/* Gogo Main Style 

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths-Spacing
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.React Siema Carousel
09.Navbar
10.Menu
11.Apps
12.Chat App
13.Survey App
14.Todo App
15.Main
16.Cards
17.Dashboard
18.Calendar
19.React Table
20.Alerts
21.Forms
22.Slider(Range)
23.Navs
24.Tooltip and popover
25.Progress
26.Rating
27.Sortable
28.Spinner
29.Croppper
30.Modal
31.Authorization
32.Html Editors
33.Icons
34.Floating Label
35.Loading
36.Context Menu
37.Videojs
38.Tables
39.Invoice Print
40. Additional Styles
*/

/* 00.Variables and Imports */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 220px;
$sub-menu-width-md: 200px;
$sub-menu-width-xs: 190px;

$navbar-height: 120px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;

$theme-color-purple: #922c88;
$theme-color-blue: #145388;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;

$info-color: #0096ef;
$success-color: #3e884f;
$warning-color: #FCB322;
$error-color: #c43d4b;


/* 01.Base */
html {
  width: 100%;
  height: 100%;
  background: $background-color;
}

:root {
  --theme-color-1: #{$theme-color-1};
  --theme-color-2: #{$theme-color-2};
  --theme-color-3: #{$theme-color-3};
  --theme-color-4: #{$theme-color-4};
  --theme-color-5: #{$theme-color-5};
  --theme-color-6: #{$theme-color-6};
  --theme-color-1-10: #{rgba($theme-color-1, 0.1)};
  --theme-color-2-10: #{rgba($theme-color-2, 0.1)};
  --theme-color-3-10: #{rgba($theme-color-3, 0.1)};
  --theme-color-4-10: #{rgba($theme-color-4, 0.1)};
  --theme-color-5-10: #{rgba($theme-color-5, 0.1)};
  --theme-color-6-10: #{rgba($theme-color-6, 0.1)};

  --primary-color: #{$primary-color};
  --foreground-color: #{$foreground-color};
  --separator-color: #{$separator-color};
}

.swiper-button-prev:after,
.swiper-button-next:after {
  background: $theme-color-1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  font-size: 20px !important;
  border-radius: 50%;
  padding-bottom: 5px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #fff;
}

.menu-home {
  float: right !important;
}

.main-pages-layout .main-page-menu li a {
  font-size: 16px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
background: #0a121d;
}

.btn-secondary {
  background: linear-gradient(to bottom, $theme-color-1, #3d2c9e) !important;
  background-size: 200% !important;
  background-position: center center;
  border: 1px solid $theme-color-1 !important;
  color: white !important;
  font-size: 16px !important;
  border-radius: 13px !important;
  box-shadow: 4px 4px 16px #00000022 !important;
  transition: 0.2s !important;

  &:hover {
    box-shadow: 4px 4px 1px #00000022 !important;
  }

}

.underground-cookies-text {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: 1rem;
}

.cookie-policy {
  background: #efeff0;
}

.auth-card {
  flex-direction: none !important;
  background: #0a121d !important;
}

.product-wrap .product-content h3 {
  font-size: 16px !important;
  font-weight: 600;
}

.product-price {
  font-size: 20px !important;
  font-weight: 600;
  color: #444 !important;
  text-align: center !important;
}

h3 {
  font-weight: 700;
}

.product-wrap {
  border-radius: 18px;
  // -webkit-box-shadow: 0 1px 4px 0 #bfbebe;
  // box-shadow: 0 1px 4px 0 #bfbebe;
  padding: 15px;
  border: 2px solid #efeeee;
  color: #fff;
}

.btn {
  border-radius: 13px !important;
  font-size: 1.1rem;
  cursor: pointer;
  line-height: 1.5;
  letter-spacing: 0.05rem;
  padding: 0.75rem;
  /*background-color: $theme-color-1;*/

}

.product-content.text-center {
  text-align: left !important;
}

.product-wrap .product-img {
  max-width: 320px;
  margin: 0 auto;
}

.product-wrap .product-img img {
  border-radius: 14px;
}

#root {
  height: 100%;
}

body {
  font-family: 'Work Sans', sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  // color: $primary-color;
  // background: $background-color;


  &.background {
    height: 100%;

    main {
      margin: 0 !important;
      height: 100%;

      .container {
        height: 100%;
      }
    }
  }
}

.fixed-background {
  background: url("/assets/img/balloon.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.background-main-pages {
  background: #fff;
}

.main-pages-layout {
  main {
    // background:#fff;
    background: #0a121d;
    overflow-x: hidden;
  }

  .main-page-menu {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 183px;
    justify-content: flex-end;

    li {
      margin-right: 6px;
      list-style: none;

      a {
        display: block;
        padding: 0.6rem;
        font-size: 1rem;
        line-height: 1;
        font-size: 14px !important;
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;

      }
    }
  }

  .main-footer-links {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: flex-start;

    li {
      margin-right: 10px;
      list-style: none;

      a {
        display: block;
        padding: 1rem;
        font-size: 1rem;
        line-height: 1;
      }
    }
  }

  .no-shadow {
    box-shadow: none;
  }

  .logo-single {
    width: 400px;
    height: 85px;
    margin-left: -10px;
  }

  h1 {
    font-size: 2.6rem;
    color: #38389a;
  }

  .flex-wrap-wrap {
    flex-wrap: wrap;
  }

  .contain-wrapper {
    padding-left: 100px;
    padding-right: 30px;
    max-width: 100%;

    form {
      width: 100%;
    }

    button {
      border-radius: 4px;
      font-size: 1.1rem;
      cursor: pointer;
      line-height: 1.5;
      font-weight: 700;
      letter-spacing: 0.05rem;
      padding: 0.75rem 2.6rem 0.6rem 2.6rem;
      background-color: $theme-color-1;
      border-color: $theme-color-1;
      color: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
      outline: initial !important;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -webkit-appearance: button;
      display: block;
    }

    input {
      font-size: 1.1rem;
      border-radius: 0.1rem;
      outline: initial !important;
      box-shadow: none !important;
      box-shadow: initial !important;
      padding: 0.7rem 0.75rem 0.65rem 0.75rem;
      line-height: 1.5;
      border: 1px solid #d7d7d7;
      background: white;
      color: #303030;
      width: 75%;
      margin-right: 20px;
      vertical-align: middle;
      margin-bottom: 15px;

      &[type="text"] {
        width: 36%;
      }
    }

    .mSalomon Garden-alert p {
      font-size: 1rem;
      padding: 15px 0;
    }
  }
}

.black-bg {
  background: #0c0c0c;

  .card {
    background: #0c0c0c;
  }
}

.gray-bg {
  background: #000;

  .container {
    height: auto;
    color: #848484;
  }
}

.footer-layout {
  border-top: 1px solid #b9b9b9;
  padding: 50px !important;
  color: #fff;

  .bottom-line {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 1px;
      width: 100%;
      border-bottom: 1px solid #2f5fa2;
    }

    &:after {
      content: "";
      display: block;
      width: 56px;
      border-bottom: 2px solid #2f5fa2;
      margin: 12px auto 24px 0;
      z-index: 1;
      position: relative;
    }
  }

  .fa-envelope-square:before {

    color: #f4d72f;
  }

  .fa-phone-square:before {
    color: #f4d72f;
  }

  .social-icons {
    a {
      margin: 0 8px 6px 0;
      display: inline-flex;
      width: 30px;
      height: 30px;
      color: #ffffff;
      background-color: #ffffff;
      justify-content: center;
      align-items: center;
      line-height: 48px;
      text-align: center;
      font-size: 18px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-border-radius: 3px;
      border-radius: 50%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      &:hover {
        background-color: #f4d72f !important;
        color: #fff !important;
        box-shadow: 4px 4px 16px #f4d72f !important;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.dark a {
      background-color: #23252a;
      color: #ffffff;
    }
  }

  .main-footer-links {
    justify-content: flex-end;

    li a {
      display: block;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      line-height: 1;
      color: #848484;

      &:hover {
        color: #fff;
      }
    }
  }
}


.img-view-wrapper {
  width: 160px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block;

  @include respond-below(sm) {
    font-size: 1.3rem;
    padding-top: 0.5rem;
  }
}

h2 {
  font-size: 1.4rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;

  @include respond-below(sm) {
    font-size: 0.85rem;
  }
}

hr {
  border-top: 1px solid $separator-color;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  background: lighten($theme-color-3, 20%);
}

::-moz-selection {
  background: lighten($theme-color-3, 20%);
}

.map-item {
  height: 400px;
  width: 100%;
}

.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative;
}

.ps__rail-y {
  width: 5px;
}

.ps__thumb-y {
  left: 0;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px;
  left: 0;
}

.ps__thumb-y {
  background-color: $separator-color;
}

.video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;

  span {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    height: 1.25em;
    border-radius: 0.75em;
    line-height: 0.65em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.75em;
    width: 2em;
    text-align: center;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 12px;
      font-size: 0;
      border-color: transparent transparent transparent $theme-color-1;
    }
  }

  &:hover,
  &:active {
    span {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.logo-single {
  width: 345px;
  height: 83px;
  background: url($logoPath) no-repeat;
  background-position: center center;
  display: block;
  margin-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
}

.list-item-heading {
  font-size: 0.8rem;
  line-height: 1.2;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-transition {
  transition: none !important;
}

.display-1 {
  color:#fff;
  @include respond-below(sm) {
    font-size: 2.8rem;
  }
}

.display-2 {
  @include respond-below(sm) {
    font-size: 2.6rem;
  }
}

.display-3 {
  @include respond-below(sm) {
    font-size: 2.4rem;
  }
}

.display-4 {
  @include respond-below(sm) {
    font-size: 2.2rem;
  }
}

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

a {
  color: $primary-color;
  transition: color $animation-time-short;

  &:hover,
  &:active {
    text-decoration: initial;
    color: $theme-color-1;
  }
}

p {
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-family: 'Work Sans', sans-serif;
}

.text-large {
  font-size: 1.9rem !important;
}

.text-one {
  font-size: 1rem !important;
}

.text-xlarge {
  font-size: 2.7rem !important;
}

.text-small {
  font-size: 0.76rem;
  line-height: 0.9rem;
}

.text-white {
  color: $button-text-color !important;
}

.text-extra-small {
  font-size: 0.6rem;
}

.text-default {
  color: $primary-color !important;
}

.text-muted {
  color: $muted-color !important;
}

.text-semi-muted {
  color: $secondary-color !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.color-theme-1 {
  color: $theme-color-1;
}

.color-theme-2 {
  color: $theme-color-2;
}

.view-icon {
  font-size: 20px;
  color: $secondary-color;

  &:hover {
    color: $theme-color-1;
  }

  &.s {
    font-size: 18px;
  }
}

#displayOptions {
  a {
    cursor: pointer;
  }

  a.active i {
    color: $theme-color-1;
  }

  button {
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $theme-color-1;
      border-color: $theme-color-1;
      color: $button-text-color;
    }
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show>.btn-outline-dark.dropdown-toggle {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $button-text-color;
  }

  .view-icon {
    svg {
      width: 19px;
    }

    .view-icon-svg {
      fill: $secondary-color;
    }

    &:hover,
    &.active {
      .view-icon-svg {
        fill: $theme-color-1;
      }
    }
  }
}

.text-theme-2 {
  color: $theme-color-2 !important;
}

.text-theme-3 {
  color: $theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
  color: $theme-color-1 !important;
}

.text-secondary {
  color: $primary-color !important;
}

.main-heading {
  border-bottom: 1px solid $separator-color;
}

.separator {
  border-bottom: 1px solid $separator-color;
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem;
}

.rounded {
  border-radius: 50px !important;
}

.img-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
}

.list-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important;

  @include respond-below(md) {
    height: 80px;
  }

  @include respond-below(xs) {
    height: 70px;
  }

  &.responsive {
    @include respond-below(md) {
      width: unset;
      height: 136px;
    }

    @include respond-below(xs) {
      width: 110px !important;
      height: 100%;
    }

    @include respond-below(xxs) {
      width: 90px !important;
      height: 100%;
    }
  }

  &.small {
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 55px;
    }

    @include respond-below(xs) {
      height: 50px;
    }
  }

  &.xsmall {
    height: 40px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 40px;
    }

    @include respond-below(xs) {
      height: 40px;
    }
  }
}

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: $theme-color-1;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: $button-text-color;

  @include respond-below(md) {
    width: 80px;
    height: 80px;
  }

  @include respond-below(xs) {
    width: 70px;
    height: 70px;
  }

  &.small {
    width: 60px;
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      width: 55px;
      height: 55px;
    }

    @include respond-below(xs) {
      width: 50px;
      height: 50px;
    }
  }
}

.white {
  color: $button-text-color !important;
}

/* 02.Buttons */

button {
  color: $primary-color;
  outline: initial !important;
}

.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px;
}

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center;
}

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px;
}

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px;
}

.btn {
  border-radius: 18px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;

  &:hover,
  &:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
      0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-empty {
  background: transparent !important;
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-sm>.btn {
  border-radius: 50px;
}

.btn.default {
  border-radius: $border-radius;
}

.btn-primary {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
    background-color: darken($theme-color-1, 8%);
    border-color: darken($theme-color-1, 8%);
  }
}

.check-button {
  cursor: default !important;
}

.check-button.btn-primary {
  background-color: $theme-color-1 !important;
  border-color: $theme-color-1 !important;
  opacity: 1;
}

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px;
}

.dropdown-menu {
  font-size: 0.8rem;
  border-radius: $border-radius;
  background: $input-background;

  border-color: rgba($primary-color, 0.15);
}

.dropdown-item {
  padding: 0.5rem 1.5rem;
  color: $primary-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $background-color;
  color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $button-text-color;
  text-decoration: none;
  background-color: $theme-color-1;
}

.dropdown-divider {
  border-color: $separator-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: darken($theme-color-1, 12%);
  border-color: darken($theme-color-1, 12%);
  color: $button-text-color;
}

.btn-secondary {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
    background-color: darken($theme-color-2, 8%);
    border-color: darken($theme-color-2, 8%);
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  background-color: darken($theme-color-2, 12%);
  border-color: darken($theme-color-2, 12%);
  color: $button-text-color;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right,
      $theme-color-1,
      darken($theme-color-1, 15%));

  &:hover {
    background: linear-gradient(to right,
        $theme-color-1,
        darken($theme-color-1, 8%));
  }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right,
      $theme-color-1,
      darken($theme-color-1, 15%));
}

.btn-secondary-gradient {
  background: linear-gradient(to right,
      $theme-color-2,
      darken($theme-color-2, 15%));

  &:hover {
    background: linear-gradient(to right,
        $theme-color-2,
        darken($theme-color-2, 8%));
  }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right,
      $theme-color-2,
      darken($theme-color-2, 15%));
}

.btn-warning {
  background-color: $warning-color;
  border-color: $warning-color;
}

.btn-info {
  background-color: $info-color;
  border-color: $info-color;
}

.btn-pink {
  background-color: #e4346d;
  border-color: #e4346d;
  color: #fff;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: $button-text-color;

  &:hover {
    color: $button-text-color;
  }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
  &:hover {
    color: $foreground-color;
  }
}

.btn-light {
  color: $dark-btn-background;
  background-color: $light-btn-background;
  border-color: $light-btn-background;

  &:hover {
    color: $dark-btn-background;
    background-color: darken($light-btn-background, 8%);
    border-color: darken($light-btn-background, 8%);
  }
}

.btn-dark {
  color: $light-btn-background;
  background-color: $dark-btn-background;
  border-color: $dark-btn-background;

  &:hover {
    color: $light-btn-background;
    background-color: darken($dark-btn-background, 5%);
    border-color: darken($dark-btn-background, 5%);
  }
}

.btn-outline-dark {
  color: $dark-btn-background;
  border-color: $dark-btn-background;

  &:hover {
    color: $foreground-color;
    background-color: $dark-btn-background;
    border-color: $dark-btn-background;
  }
}

.btn-outline-white {
  color: $button-text-color;
  border-color: $button-text-color;
  background-color: initial;

  &:hover {
    color: $theme-color-1;
    background-color: $button-text-color;
  }
}

.btn-outline-light {
  color: $light-btn-background;
  border-color: $light-btn-background;

  &:hover {
    color: $foreground-color;
    background-color: $light-btn-background;
    border-color: $light-btn-background;
  }
}

.btn-outline-primary {
  color: $theme-color-1;
  border-color: $theme-color-1;

  &:hover {
    color: $foreground-color;
    background-color: $theme-color-1;
    border-color: $theme-color-1;
  }
}

.btn-outline-theme-3 {
  background: unset;
  color: $theme-color-3;
  border-color: $theme-color-3;

  &:hover {
    background-color: $theme-color-3;
    border-color: $theme-color-3;
    color: $foreground-color;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $foreground-color;
}

.btn-outline-secondary {
  color: $theme-color-2;
  border-color: $theme-color-2;

  &:hover {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $foreground-color;
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $foreground-color;
}

.btn-header-light {
  color: $separator-color;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $separator-color;
  }
}

.btn-header-primary {
  color: $theme-color-1;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $theme-color-1;
  }
}

.btn-header-secondary {
  color: $theme-color-2;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $theme-color-2;
  }
}

.btn-header-primary-light {
  color: $theme-color-3;
  border-color: transparent;
  background: transparent;

  &:hover {
    background-color: transparent;
    border-color: $theme-color-3;
  }
}

.btn-xl,
.btn-group-xl>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $theme-color-1;
  border-color: $theme-color-1;
  color: $foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $foreground-color;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-link {
  color: $theme-color-1;
}

.btn-link:hover {
  color: rgba($theme-color-1, 0.8);
  text-decoration: underline;
}

.white-underline-link {
  color: $button-text-color;
  text-decoration: underline;

  &:hover,
  &:active {
    color: $button-text-color;
    text-decoration: initial;
  }
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: $button-text-color;
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }

  &.btn-primary:disabled {
    opacity: 1;
    background: darken($theme-color-1, 10%);
    border-color: darken($theme-color-1, 10%);
  }

  &.btn-secondary:disabled {
    opacity: 1;
    border-color: darken($theme-color-2, 10%);
  }
}

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;

  &.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
  }

  &.small-icon {
    font-size: 12px;
    line-height: 32px;
  }
}

.top-right-button {
  width: calc(100% - 80px);

  @include respond-below(xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.top-right-button-single {
  width: unset;

  @include respond-below(md) {
    width: calc(100%);
  }
}

/* 03.Widths-Spacing */
.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-16 {
  width: 16% !important;
}

.w-9 {
  width: 9% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 6% !important;
}

.w-xs-100 {
  @include respond-below(sm) {
    width: 100% !important;
  }
}

.w-sm-100 {
  @include respond-below(md) {
    width: 100% !important;
  }
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.depth-1 {
  @include depth(1);
}

.depth-2 {
  @include depth(2);
}

.min-width-zero {
  min-width: 0;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.float-none-xs {
  @include respond-below(xs) {
    float: initial !important;
  }
}

/* 04.Borders */
.border {
  border: 1px solid $separator-color-light !important;
}

.border-right {
  border-right: 1px solid $separator-color-light !important;
}

.border-left {
  border-left: 1px solid $separator-color-light !important;
}

.border-top {
  border-top: 1px solid $separator-color-light !important;
}

.border-bottom {
  border-bottom: 1px solid $separator-color-light !important;
}

.border-primary,
.border-theme-1 {
  border-color: $theme-color-1 !important;
}

.border-theme-2 {
  border-color: $theme-color-2 !important;
}

.border-theme-3 {
  border-color: $theme-color-3 !important;
}

.border-secondary {
  border-color: $primary-color !important;
}

/* 05.Badges */

.bg-theme-2,
.badge-theme-2 {
  background-color: $theme-color-2 !important;
  color: $button-text-color;
}

.bg-theme-3,
.badge-theme-3 {
  background-color: $theme-color-3 !important;
  color: $button-text-color;
}

.bg-primary,
.bg-theme-1,
.badge-primary,
.badge-theme-1 {
  background-color: $theme-color-1 !important;
  color: $button-text-color;
}

.bg-secondary,
.badge-secondary {
  background-color: $theme-color-2 !important;
  color: $button-text-color;
}

.bg-muted {
  background-color: $muted-color;
}

.bg-semi-muted {
  background-color: $background-color;
}

.badge-warning {
  background-color: $warning-color;
}

.badge-success {
  background-color: $success-color;
}

.badge-info {
  background-color: $info-color;
}

.badge-danger {
  background-color: $error-color;
}

.badge-success,
.badge-danger,
.badge-warning,
.badge-info {
  color: $button-text-color;
}

.badge {
  padding: 0.55em 0.75em 0.6em 0.75em;
  font-size: 74%;

  &.badge-pill {
    padding-right: 1.25em;
    padding-left: 1.25em;
  }

  &.badge-top-left {
    top: 10px;
    left: -7px;
  }

  &.badge-top-left-2 {
    top: 40px;
    left: -7px;
  }

  &.badge-top-left-3 {
    top: 70px;
    left: -7px;
  }

  &.badge-top-right {
    top: 8px;
    right: -7px;
  }

  &.badge-top-right-2 {
    top: 40px;
    right: -7px;
  }
}

.badge-light {
  background-color: $light-btn-background;
  color: $dark-btn-background;
}

.badge-dark {
  background-color: $dark-btn-background;
  color: $light-btn-background;
}

.badge-outline-primary,
.badge-outline-theme-1 {
  background: unset;
  border: 1px solid $theme-color-1;
  color: $theme-color-1;
}

.badge-outline-secondary,
.badge-outline-theme-2 {
  background: unset;
  border: 1px solid $theme-color-2;
  color: $theme-color-2;
}

.badge-outline-theme-3 {
  background: unset;
  border: 1px solid $theme-color-3;
  color: $theme-color-3;
}

.badge-outline-success {
  background: unset;
  border: 1px solid $success-color;
  color: $success-color;
}

.badge-outline-danger {
  background: unset;
  border: 1px solid $error-color;
  color: $error-color;
}

.badge-outline-warning {
  background: unset;
  border: 1px solid $warning-color;
  color: $warning-color;
}

.badge-outline-info {
  background: unset;
  border: 1px solid $info-color;
  color: $info-color;
}

.badge-outline-light {
  background: unset;
  border: 1px solid $light-btn-background;
  color: $light-btn-background;
}

.badge-outline-dark {
  background: unset;
  border: 1px solid $dark-btn-background;
  color: $dark-btn-background;
}

/* 06.Breadcrumb */
.breadcrumb-container {
  .breadcrumb {
    @include respond-below(md) {
      padding: 0;
    }
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "|";
}

/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid $separator-color;
}

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: $foreground-color;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  top: 0;
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset;
}

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $secondary-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: $theme-color-1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: $theme-color-1;
}

/* 08.React Siema Carousel */
.react-siema-container {
  overflow: hidden;
  padding-bottom: 15px;
  padding-top: 5px;
}

.slider-nav {

  .left-arrow,
  .right-arrow {
    font-size: 20px;
    color: $theme-color-1;
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px;
    padding-top: 14px;
  }

  .slider-dot-container {
    display: inline-block;
  }

  .btn {

    &:hover,
    &:focus,
    &:active {
      text-decoration: initial;
    }
  }
}

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: $separator-color;
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;

  &.active {
    background: $theme-color-1;
  }
}

.react-siema-container .card .card-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.react-siema-container .card .w-50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* 09.Navbar*/
.navbar {
  background: $foreground-color;
  height: $navbar-height;
  padding: 1.5rem $main-margin 1.5rem 0;
  @include depth(1);

  #notificationDropdown {
    width: 260px;
    padding: 1.5rem;
    height: 280px;
    right: 15px;
  }

  #iconMenuDropdown {
    width: 530px;
    padding: 1.5rem;
    height: 290px;
  }

  .icon-menu-item {
    width: 120px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.7rem;
    padding: 10px;
    border-radius: 7px;
    color: rgba($primary-color, 0.8);

    i {
      font-size: 28px;
      line-height: 42px;
    }

    span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px;
    }

    &:hover,
    &:focus {
      color: $theme-color-1;
    }
  }

  .menu-button-mobile {
    color: $secondary-color;
    text-align: center;

    svg {
      height: 12px;
      fill: $primary-color;
    }

    @include respond-below(sm) {
      width: 20px;
    }
  }

  .menu-button {
    color: $secondary-color;
    width: $main-menu-width;
    text-align: center;

    svg {
      height: 12px;
    }

    .main {
      fill: $primary-color;
      transition: fill $menu-collapse-time;
    }

    .sub {
      fill: $primary-color;
      transition: fill $menu-collapse-time;
    }

    &:hover {
      color: $theme-color-1;
    }

    @include respond-below(sm) {
      width: 60px;
    }
  }

  .navbar-logo {
    width: 110px;
    height: 40px;

    .logo {
      width: 158%;
      height: 109%;
      background: url($logoPath2) no-repeat;
      background-position: center center;
      margin-left: 10px;
    }

    .logo-mobile {
      width: 110%;
      height: 90%;
      background: url($logoPathMobile) no-repeat;
      background-position: center center;
      margin-left: 10px;
    }

    @include respond-below(sm) {
      width: 110px;
    }

    @include respond-below(xxs) {
      width: 20px;
    }
  }

  .language-button {
    background: $background-color;
    border: initial;
    font-size: 0.8rem;
    color: $secondary-color;
    padding: 0.6rem 1rem;

    @include respond-below(xs) {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  .search {
    position: relative;
    width: $sub-menu-width;
    border-radius: 20px;
    background: $background-color;

    input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      color: $primary-color;
    }

    .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: $secondary-color;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -8px;
      right: 3px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $theme-color-1;
      }
    }

    @include respond-below(md) {
      width: $sub-menu-width/2;

      input {
        width: 85%;
      }
    }

    @include respond-below(sm) {
      width: 30px;
      height: 30px;
      background: initial;
      margin-left: 0.6rem;
      color: rgba($primary-color, 0.7);

      input {
        display: none;
      }

      .search-icon {
        font-size: 17px;
        width: 30px;
        height: 30px;
        bottom: -3px;
        right: 0;
        color: inherit;
      }

      &.mobile-view {
        display: block;
        width: 100%;
        position: fixed;
        z-index: 2;
        background: $foreground-color;
        left: 0;
        top: 0;
        height: $navbar-height-xs;
        margin-left: 15px;

        input {
          display: block;
          width: 100%;
          height: 70px;
          padding-left: 0;
        }

        span {
          top: 50%;
          transform: translateY(-50%);
          right: 25px;
        }
      }
    }
  }

  .header-icons {
    margin-right: 1rem;

    @include respond-below(xs) {
      margin-right: 0;
    }
  }

  .header-icon {
    font-size: 16px;
    color: $secondary-color;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial;

    @include respond-below(xs) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    &:hover {
      color: $theme-color-1;
    }

    &#fullScreenButton i:last-of-type {
      display: none;
    }

    &.notificationButton {
      .count {
        font-size: 9px;
        color: $theme-color-1;
        border: 1px solid $theme-color-1;
        border-radius: 10px;
        position: absolute;
        width: 18px;
        height: 15px;
        text-align: center;
        font-weight: 700;
        top: 2px;
        right: 2px;
        line-height: 14px;

        @include respond-below(xs) {
          right: -1px;
        }
      }
    }
  }

  .user {
    color: lighten($primary-color, 30%);
    position: relative;

    img {
      margin-left: 10px;
      border-radius: 30px;
      width: 40px;
    }

    @include respond-below(md) {
      .name {
        display: none;
      }
    }

    @include respond-below(sm) {
      img {
        width: 30px;
      }

      margin-left: initial;

      &:after {
        font-size: 11px;
        width: 14px;
        height: 14px;
        bottom: -3px;
        right: -3px;
      }
    }
  }

  @include respond-below(xl) {
    height: $navbar-height-lg;
  }

  @include respond-below(lg) {
    height: $navbar-height-md;
    padding: 1.5rem $main-margin-md 1.5rem 0;
  }

  @include respond-below(sm) {
    height: $navbar-height-xs;
    padding: $main-margin-xs;
  }
}

#app-container {

  &.sub-hidden .menu-button,
  &.menu-sub-hidden .menu-button {
    .sub {
      fill: $secondary-color;
    }
  }

  &.main-hidden .menu-button,
  &.menu-hidden .menu-button {

    .main,
    .sub {
      fill: $secondary-color;
    }
  }
}

/* 10.Menu*/
#app-container .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: $navbar-height;
  z-index: 4;
  height: calc(100% - #{$navbar-height});

  .scrollbar-container {
    margin-right: 0;
    padding-right: 0;
  }

  .main-menu {
    width: $main-menu-width;
    height: calc(100% - #{$navbar-height});
    background: $foreground-color;
    z-index: 3;
    position: fixed;
    transition: transform $menu-collapse-time;
    padding-top: 2px;
    left: 0;

    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%;

      .ps__thumb-y {
        right: 0;
      }
    }

    &.main-hidden {
      transform: translateX(-$main-menu-width);
    }

    ul li {
      position: relative;

      span {
        text-align: center;
        padding: 0 10px;
        line-height: 14px;
      }

      a {
        height: $main-menu-item-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid $separator-color-light;
        color: $primary-color;
        transition: color $menu-collapse-time;
        transition: background $menu-collapse-time;

        &:hover,
        &:focus {
          color: $theme-color-1;
          background: $background-color;
        }
      }

      i {
        font-size: 32px;
        line-height: 42px;
      }

      &.active a {
        color: $theme-color-1;
      }

      &.active:after {
        content: " ";
        background: $theme-color-1;
        border-radius: 10px;
        position: absolute;
        width: 6px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      width: $main-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});

      ul li i {
        font-size: 28px;
        line-height: 38px;
      }

      ul li a {
        height: $main-menu-item-height-mobile;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }

  .sub-menu {
    width: $sub-menu-width;
    background: $foreground-color;
    z-index: 2;
    position: fixed;
    left: $main-menu-width;
    border-left: 1px solid $separator-color-light;
    @include depth(2);
    transition: transform $menu-collapse-time;
    height: calc(100% - #{$navbar-height});

    .scroll {
      height: 100%;
      margin-top: 2px;
      margin-right: unset;
      padding-right: unset;

      .ps__thumb-y {
        right: 0;
      }
    }

    .ps {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    ul {
      display: none;

      li {
        margin-bottom: 10px;
        margin-left: 30px;

        a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
        }

        i {
          font-size: 1.3em;
          margin-right: 10px;
          color: $secondary-color;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block;
        }

        &.active i,
        &.active a {
          color: $theme-color-1;
        }

        @include respond-below(sm) {
          margin-left: 15px;
        }
      }

      .third-level-menu {
        display: block;

        li {
          margin-bottom: 2px;

          a {
            color: $primary-color;

            i {
              color: $primary-color;
            }

            &.active {
              color: $theme-color-1;

              i {
                color: $theme-color-1;
              }
            }
          }
        }
      }
    }

    @include respond-below(xl) {
      left: $main-menu-width-lg;
      width: $sub-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
      left: $main-menu-width-md;
      width: $sub-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
      left: $main-menu-width-xs;
      width: $sub-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});
    }
  }

  @include respond-below(xl) {
    padding-top: $navbar-height-lg;
  }

  @include respond-below(lg) {
    padding-top: $navbar-height-md;
  }

  @include respond-below(sm) {
    padding-top: $navbar-height-xs;
    @include depth(1);
  }
}

//Menu Config Main Menu Sub Menu
#app-container {

  &.sub-hidden .sub-menu,
  &.menu-sub-hidden .sub-menu,
  &.menu-hidden .sub-menu {
    transform: translateX(-$sub-menu-width);

    @include respond-below(xl) {
      transform: translateX(-$sub-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX(-$sub-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX(-$sub-menu-width-xs);
    }
  }

  &.main-hidden .main-menu,
  &.menu-hidden .main-menu {
    transform: translateX(-$main-menu-width);

    @include respond-below(xl) {
      transform: translateX(-$main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX(-$main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX(-$main-menu-width-xs);
    }
  }

  &.main-hidden.sub-hidden .sub-menu,
  &.menu-hidden .sub-menu {
    transform: translateX(-$sub-menu-width - $main-menu-width);

    @include respond-below(xl) {
      transform: translateX(-$sub-menu-width-lg - $main-menu-width-lg);
    }

    @include respond-below(lg) {
      transform: translateX(-$sub-menu-width-md - $main-menu-width-md);
    }

    @include respond-below(sm) {
      transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
    }
  }

  //Mobile
  &.menu-mobile {
    .main-menu {
      transform: translateX(-$main-menu-width-xs);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs - 50);
    }
  }

  //Show Temporary
  &.main-show-temporary {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width);
    }

    @include respond-below(xl) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX(-$sub-menu-width-lg);
      }
    }

    @include respond-below(lg) {
      .main-menu {
        transform: translateX(0);
      }

      .sub-menu {
        transform: translateX(-$sub-menu-width-md);
      }
    }

    @include respond-below(sm) {
      .sub-menu {
        transform: translateX(-$sub-menu-width-xs);
      }
    }
  }

  &.sub-show-temporary,
  &.menu-mobile.sub-show-temporary {
    .sub-menu {
      transform: translateX(0);
    }
  }
}

/* 11.Apps*/
.app-menu {
  z-index: 1;
  @include depth(1);
  width: $app-menu-width;
  float: right;
  background: $foreground-color;
  transition: transform $menu-collapse-time;
  height: calc(100% - #{$navbar-height});
  position: fixed;
  right: 0;
  top: $navbar-height;

  @include respond-below(xl) {
    top: $navbar-height-lg;
    height: calc(100% - #{$navbar-height-lg});
  }

  @include respond-below(lg) {
    top: $navbar-height-md;
    transform: translateX($app-menu-width);
    height: calc(100% - #{$navbar-height-md});
  }

  @include respond-below(sm) {
    top: $navbar-height-xs;
    height: calc(100% - #{$navbar-height-xs});
  }

  .scrollbar-container {
    margin-right: unset;
    padding-right: unset;

    .ps__rail-y {
      right: 2px !important;
    }
  }

  &.shown {
    transform: translateX(0);
  }

  .app-menu-button {
    cursor: pointer;
    position: absolute;
    left: -28px;
    background: $foreground-color;
    top: $main-margin - 15;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: $secondary-color !important;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0;

    @include respond-below(xl) {
      top: $main-margin-lg - 14;
    }

    @include respond-below(lg) {
      top: $main-margin-md - 13;
    }

    @include respond-below(sm) {
      top: $main-margin-xs - 2;
    }
  }

  ul {
    li {
      margin-bottom: 5px;

      a {
        font-size: 13px;
        display: block;
        padding: 3px 0;

        &:hover i {
          color: $theme-color-1;
        }
      }

      i {
        font-size: 1.2em;
        margin-right: 10px;
        color: $secondary-color;
        vertical-align: unset;

        @include respond-below(sm) {
          font-size: 20px;
        }
      }

      &.active i,
      &.active a {
        color: $theme-color-1;
      }
    }
  }
}

.app-row {
  padding-right: $app-menu-width;

  @include respond-below(lg) {
    padding-right: 0;
  }
}

/* 12.Chat App*/
.chat-app {
  .scrollbar-container {
    padding-left: 10px;
    margin-left: -10px;
    padding-right: 10px;
    margin-right: 0;
    height: calc(100vh - #{$navbar-height} - 270px);

    @include respond-below(xl) {
      height: calc(100vh - #{$navbar-height-lg} - 270px);
    }

    @include respond-below(lg) {
      height: calc(100vh - #{$navbar-height-md} - 270px);
    }

    @include respond-below(sm) {
      height: calc(100vh - #{$navbar-height-xs} - 220px);
    }

    .ps__thumb-y {
      right: 10px;
    }
  }

  .chat-text-left {
    padding-left: 64px;
  }

  .chat-text-right {
    padding-right: 64px;
  }

  .list-item-heading {
    @include respond-below(sm) {
      font-size: 0.9rem;
    }
  }

  .card .card-body {
    @include respond-below(sm) {
      padding: 0.75rem;
    }
  }
}

.chat-app-tab-content {
  height: calc(100% - 45px);
}

.chat-app-tab-pane {
  height: calc(100% - 45px);
}

.chat-input-container {
  width: 100%;
  height: 90px;
  background: $input-background;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  @include depth(1);
  padding-left: $main-menu-width + $sub-menu-width + $main-margin;
  padding-right: $app-menu-width + $main-margin;

  @include respond-below(xl) {
    padding-left: $main-menu-width-lg + $main-margin-lg;
    padding-right: $app-menu-width + $main-margin-lg;
  }

  @include respond-below(lg) {
    padding-left: $main-menu-width-md + $main-margin-md;
    padding-right: $main-margin-md;
  }

  @include respond-below(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }

  input {
    border: initial;
    width: unset;
    height: 90px;
    padding-left: 0;
    display: inline-block;
  }

  .send-button {
    @include respond-below(md) {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
  }
}

/* 13.Survey App*/
.survey-app {
  .answers {
    .badge {
      color: $secondary-color;
      font-size: 13px;
      padding-left: 0.75em;
      padding-right: 0.75em;
    }

    input {
      padding-right: 70px;
    }
  }

  .question {
    &.edit-quesiton {
      .edit-mode {
        display: block;
      }

      .view-mode {
        display: none;
      }

      .view-button {
        display: inline-block;
      }

      .edit-button {
        display: none;
      }
    }

    &.view-quesiton {
      .edit-mode {
        display: none;
      }

      .view-mode {
        display: block;
      }

      .view-button {
        display: none;
      }

      .edit-button {
        display: inline-block;
      }
    }
  }

  .survey-question-types {
    .btn-group-icon {
      font-size: 21px;
      line-height: 28px;
    }

    .btn {
      padding-bottom: 0.3rem;
    }
  }
}

.input-icons {
  position: absolute;
  right: 0;
  top: 9px;
}

/* 14.Todo App*/
.heading-icon {
  font-size: 20px;
  color: $theme-color-1;
  margin-right: 5px;
  line-height: inherit;
  vertical-align: middle;
}

.todo-details {
  margin-top: 20px;
  margin-left: 40px;
}

.heading-number {
  border: 1px solid $theme-color-1;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: $theme-color-1;
}

/* 15.Main*/
main {
  margin-left: $sub-menu-width + $main-menu-width + $main-margin - 25;
  margin-top: $navbar-height + $main-margin - 15;
  margin-right: $main-margin - 20;
  margin-bottom: $main-margin - 20;
  transition: margin-left $menu-collapse-time;

  &.sub-hidden {
    margin-left: $main-menu-width + $main-margin;
  }

  &.main-hidden {
    margin-left: $main-margin;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include respond-below(xl) {
    margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg - 25;
    margin-right: $main-margin-lg - 25;
    margin-top: $main-margin-lg + $navbar-height-lg -10;
    margin-bottom: 30px;
  }

  @include respond-below(lg) {
    margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md - 25;
    margin-right: $main-margin-md - 25;
    margin-top: $main-margin-md + $navbar-height-md -10;
    margin-bottom: 20px;
  }

  @include respond-below(sm) {
    margin-left: $main-margin-xs !important;
    margin-right: $main-margin-xs !important;
    margin-top: $main-margin-xs + $navbar-height-xs !important;
    margin-bottom: 0;
  }

  @include respond-below(xs) {
    margin-bottom: 0;
  }
}

//Menu Config Main
#app-container {

  &.sub-hidden main,
  &.menu-sub-hidden main,
  &.menu-hidden main {
    margin-left: $main-menu-width + $main-margin;
  }

  &.main-hidden main,
  &.menu-hidden main {
    margin-left: $main-margin;
  }

  @include respond-below(xl) {

    &.sub-hidden main,
    &.menu-sub-hidden main,
    &.menu-hidden main {
      margin-left: $main-menu-width-lg + $main-margin-lg;
    }

    &.main-hidden main,
    &.menu-hidden main {
      margin-left: $main-margin-lg;
    }
  }

  @include respond-below(lg) {

    &.sub-hidden main,
    &.menu-sub-hidden main,
    &.menu-hidden main {
      margin-left: $main-menu-width-md + $main-margin-md;
    }

    &.main-hidden main,
    &.menu-hidden main {
      margin-left: $main-margin-md;
    }
  }
}

/* 16.Cards*/
.card {
  border: initial;
  background: $foreground-color;

  .card-header .card-icon {
    right: 5px;
    top: 5px;

    i {
      font-size: 12px;
      color: $separator-color;
    }
  }

  .card-subtitle {
    margin: 0;
    margin-bottom: 1rem;
  }

  .card-header .handle {
    cursor: default;
  }

  border-radius: calc(0.15rem - 1px);
  @include depth(1);

  .card-body {
    padding: 1.75rem;

    @include respond-below(xs) {
      padding: 1.25rem;
    }

    &.sm {
      padding: 1.25rem 1.75rem;
    }
  }

  .card-title {
    margin-bottom: 2rem;
    color:#fff;
    @include respond-below(sm) {
      margin-bottom: 1.25rem;
    }
  }
}

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset;
}

.card-img-fluid {
  border-radius: calc(0.25rem - 1px);
  object-fit: cover;
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-top-right-radius: calc(0.15rem - 1px);
}

.card-img-left {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover;
}

.card-img-right {
  width: 100%;
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover;
}

.card-img-overlay {
  background: rgba(#000000, 0.5);
  padding: 1.75rem;

  @include respond-below(xs) {
    padding: 1rem;
  }
}

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0;

  @include respond-below(xs) {
    padding: 0.35rem;
  }
}

.card-header {
  border: initial;
  background: initial;
  padding-top: 0;
}

.item-check {
  pointer-events: none;
}

.check-all {
  padding-top: 10px;
}

/* 17.Dashboard*/

.dashboard-line-chart {
  height: 283px;
}

.dashboard-quick-post {
  min-height: 263px;
}

.dashboard-list-with-thumbs {
  height: 500px;
}

.dashboard-logs {
  height: 270px;
}

.dashboard-list-with-user {
  height: 270px;
}

.dashboard-donut-chart {
  height: 270px;
}

.dashboard-small-chart {
  height: 150px;

  .chart {
    height: 75px;
  }

  .lead {
    font-size: 1.4rem;
  }
}

.dashboard-small-chart-analytics {
  height: 180px;

  .chart {
    height: 85px;
  }

  .lead {
    font-size: 1.4rem;
  }
}

.dashboard-filled-line-chart {
  height: 340px;

  .chart {
    height: 200px;
  }
}

.dashboard-sq-banner {
  background-image: linear-gradient(to right top,
      $gradient-color-2,
      $gradient-color-3,
      $gradient-color-1 );
  background-size: cover;
  height: 385px;
  transition: 0.5s;
  background-size: 350% auto;
  cursor: pointer;

  .card-body {
    width: 270px;
  }

  .lead {
    line-height: 2.3rem;
  }

  &:hover {
    background-position: right top;
  }
}

.dashboard-link-list {
  height: 385px;
}

.dashboard-progress {
  height: 385px;
}

.dashboard-top-rated {
  height: 300px;

  @include respond-below(md) {
    height: unset;
  }

  .react-siema-container {
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    img {
      height: 120px;
      display: flex;
      object-fit: cover;
      width: 100%;
    }

    .react-rater {
      display: inline-block !important;
    }
  }
}

.dashboard-search {
  height: 650px;
  background: url(/assets/img/plane.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $foreground-color;

  .card-body {
    padding: 120px 50px 30px 50px;

    @include respond-below(xl) {
      padding: 80px 30px 30px 30px;
    }
  }

  .form-container {
    height: 400px;
    border-radius: $border-radius;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    background-color: $foreground-color;
  }
}

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;

  .react-siema-container {
    padding-bottom: 0px;
    padding-top: 10px;

    .icon-row-item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-body {
    padding: 2rem 0.5rem;
  }

  .card-text {
    color: $secondary-color;
    height: 30px;
    line-height: 26px;
  }

  .lead {
    color: $theme-color-1;
    margin-bottom: 0;
  }

  i {
    font-size: 46px;
    line-height: 66px;
    color: $theme-color-1;
  }

  .card {
    transition: box-shadow $animation-time-long;
    cursor: pointer;
  }

  @include respond-below(xl) {
    .card-text {
      height: 48px;
      line-height: 18px;
    }

    .lead {
      font-size: 1.6rem;
    }

    i {
      font-size: 32px;
      line-height: 47px;
    }
  }
}

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid $theme-color-1;
  border-radius: 14px;
  display: inline-block;
}

/* 18.Calendar */
.big-calendar-header {
  margin-bottom: 1em;
}

.rbc-month-header {
  min-height: 50px;
}

.rbc-today {
  background: initial;
}

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: $theme-color-1;
  color: $foreground-color;
  border: 1px solid $theme-color-1;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important;

  span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important;
  }

  &:hover {
    background-color: transparent;
    border-color: lighten($theme-color-1, 10%);
    color: $theme-color-1;
  }
}

.calendar-prev-btn {
  margin-right: 5px;
}

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important;
}

.rbc-month-row {
  min-height: 5em;
  z-index: 1;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.rbc-month-view {
  border: initial;
}

.rbc-off-range-bg {
  background: initial;
}

.rbc-off-range {
  color: $primary-color;
  opacity: 0.3;
}

.rbc-day-bg+.rbc-day-bg,
.rbc-month-row+.rbc-month-row,
.rbc-header+.rbc-header,
.rbc-header {
  border-color: $separator-color-light !important;
}

.rbc-header {
  padding: 15px 5px;
  color: $theme-color-1;
}

.rbc-date-cell {
  padding: 10px;

  a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block;
  }
}

.rbc-date-cell.rbc-now a {
  background: $theme-color-1;
  color: $foreground-color;
}

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: $theme-color-1;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 6em;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}

.fc-bootstrap4 td.fc-today {
  background: initial;

  .fc-day-number {
    background: $theme-color-1;
    color: $button-text-color;
  }
}

.fc-day-grid-container {
  height: 100%;
  overflow: visible;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px;
}

.fc-view,
.fc-view>table {
  border-top: initial;
}

.fc table {
  border: initial;
}

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: initial;
  border-bottom: initial;
  border-left: initial;
}

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: initial;
  border-bottom: initial;
}

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: initial;
  border-bottom: initial;
}

.fc-body .fc-row:last-of-type td {
  border-bottom: initial;
}

.fc tbody>tr>td.fc-widget-content:first-of-type {
  border-left: initial;
  border-right: initial;
  border-bottom: initial;
}

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: $separator-color-light !important;
}

.fc-day-header {
  color: $theme-color-1;

  span {
    padding: 10px;
    display: inline-block;
  }
}

.fc-event {
  border: initial;
}

.fc-event,
.fc-event-dot {
  background-color: $theme-color-2;
  color: $button-text-color !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

/* 19.React Table */
.ReactTable {
  border: initial;

  .scrollbar-container {
    margin: initial;
    padding: initial;
  }

  .rt-thead.-filters input {
    border-radius: $border-radius;
    outline: initial !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    padding: 0.7rem 0.75rem 0.65rem 0.75rem;
    line-height: 1.5;
    border: 1px solid $separator-color;
    background: $input-background;
    color: $primary-color;
  }
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding-top: 20px;
  padding-bottom: 10px;
}

.react-table-fixed-height {
  height: 500px;
}

.ReactTable .rt-thead.-header {
  box-shadow: initial;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: initial;
  border: initial;
  text-align: left;
  font-weight: 700;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid $separator-color-light;
}

.ReactTable .list-item-heading {
  margin-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: initial;
  white-space: normal;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 $theme-color-1;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 $theme-color-1;
}

/* 20.Alerts */
.alert {
  border-radius: 0;
}

.alert-primary {
  color: $theme-color-1;
  background-color: rgba($theme-color-1, 0.2);
  border-color: rgba($theme-color-1, 0.1);
}

.alert-secondary {
  color: $theme-color-2;
  background-color: rgba($theme-color-2, 0.2);
  border-color: rgba($theme-color-2, 0.1);
}

.alert-success {
  color: $success-color;
  background-color: rgba($success-color, 0.2);
  border-color: rgba($success-color, 0.1);
}

.alert-info {
  color: $info-color;
  background-color: rgba($info-color, 0.2);
  border-color: rgba($info-color, 0.1);
}

.alert-warning {
  color: $warning-color;
  background-color: rgba($warning-color, 0.2);
  border-color: rgba($warning-color, 0.1);
}

.alert-danger {
  color: $error-color;
  background-color: rgba($error-color, 0.2);
  border-color: rgba($error-color, 0.1);
}

.alert-light {
  color: $light-btn-background;
  background-color: rgba($light-btn-background, 0.2);
  border-color: rgba($light-btn-background, 0.1);
}

.alert-dark {
  color: $dark-btn-background;
  background-color: rgba($dark-btn-background, 0.2);
  border-color: rgba($dark-btn-background, 0.1);
}

.alert-dismissible .close {
  text-shadow: initial;
}

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem;
}

div[data-notify="container"] {
  padding: 18px;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

/* 21.Forms */
.av-invalid .av-label,
.form-group.text-danger,
.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label,
.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: $primary-color !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: $separator-color;
}

.invalid-feedback {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: $foreground-color;
  border: 1px solid $theme-color-1;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 11;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  @include depth(1);

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $theme-color-1;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $foreground-color;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.tooltip-right-bottom .invalid-feedback {
  left: initial;
  right: 0;
  transform: translateX(0);

  &::before,
  &::after {
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-bottom .invalid-feedback {
  left: 0;
  right: initial;
  transform: translateX(0);

  &::before,
  &::after {
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-center-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(-50%) translateY(50%);
  top: initial;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px $theme-color-1;
    border-bottom: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
  }
}

.tooltip-right-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: initial;
  right: 0;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px $theme-color-1;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-top .invalid-feedback {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: 0;
  right: initial;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px $theme-color-1;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-label-right .invalid-feedback {
  transform: translateX(0) translateY(-50%);
  top: 16px;
  left: 50px;

  &::before {
    content: "";
    position: absolute;
    left: -5px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px $theme-color-1;
    bottom: initial;
    top: 12px;
    width: 5px;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    left: -4px;
    right: initial;
    margin-left: 0;
    width: 5px;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px $foreground-color;
    bottom: initial;
    top: 12px;
    width: 5px;
  }
}

.error-l-0 .invalid-feedback {
  left: 0px;
}

.error-l-25 .invalid-feedback {
  left: 25px;
}

.error-l-50 .invalid-feedback {
  left: 50px;
}

.error-l-75 .invalid-feedback {
  left: 75px;
}

.error-l-100 .invalid-feedback {
  left: 100px;
}

.error-l-125 .invalid-feedback {
  left: 125px;
}

.error-l-150 .invalid-feedback {
  left: 150px;
}

.error-l-175 .invalid-feedback {
  left: 175px;
}

.error-l-200 .invalid-feedback {
  left: 200px;
}

.error-l-225 .invalid-feedback {
  left: 225px;
}

.error-l-250 .invalid-feedback {
  left: 250px;
}

.error-l-275 .invalid-feedback {
  left: 275px;
}

.error-t-negative .invalid-feedback {
  top: -10px;
}

.form-inline {
  .form-group {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.form-check-label,
.custom-control-label {
  line-height: 24px !important;
}

.react-tagsinput {
  background-color: $input-background !important;
  border: 1px solid $separator-color !important;
  outline: initial !important;
  box-shadow: initial !important;
}

.react-tagsinput--focused {
  border: 1px solid rgba($theme-color-1, 0.6) !important;
}

.react-tagsinput-tag {
  background: $theme-color-1 !important;
  border-radius: 15px !important;
  padding: 0px 10px !important;
  margin-bottom: 0px !important;
  display: inline-block !important;
  font-size: 12px !important;
  color: $foreground-color !important;
  border: initial !important;
}

.form-control:focus {
  background: $input-background;
  color: $primary-color;
}

.bootstrap-tagsinput {
  width: 100%;
}

.bootstrap-tagsinput input {
  padding: 0;
}

.form-control:focus {
  border-color: rgba($theme-color-1, 0.6);
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 3px);
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: rgba($primary-color, 0.25) !important;
}

.custom-control-input:active~.custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='" +encodecolor($button-text-color )+"' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='" +encodecolor($button-text-color )+"' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='" +encodecolor($button-text-color )+"'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  background-color: initial;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
}

.custom-control-label::before {
  border: 1px solid $muted-color;
  background: initial;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  background-color: $theme-color-1;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
  border: 1px solid $theme-color-1;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: $theme-color-1;
  box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
}

.custom-control-label::before {
  top: 0.2rem;
}

.custom-control-label::after {
  top: 0.2rem;
}

.btn.rotate-icon-click i {
  transition: transform 0.5s;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid $button-text-color;
}

.btn .custom-control-input:checked~.custom-control-label::before {
  border: 1px solid $button-text-color;
}

.btn-group-icon {
  line-height: 22px;
}

.autosuggest {
  position: relative;
}

.react-autosuggest__suggestions-container {
  border-radius: $border-radius;
  z-index: 20;
  box-shadow: initial;
  margin-top: -1px;
  background: $foreground-color;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: $foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: lighten($theme-color-1, 10%) !important;
    color: $foreground-color !important;
  }
}

.react-autosuggest__input--open {
  border: 1px solid rgba($theme-color-1, 0.6) !important;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba($theme-color-1, 0.6);
  border-top: initial;
  border-width: 1px !important;
}

.react-select {
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
  background: $foreground-color !important;
}

.react-select .react-select__dropdown-indicator {
  color: $secondary-color;
}

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: $primary-color;
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: $theme-color-1 !important;
  }
}

.react-select__control {
  border-radius: $border-radius !important;
  border: 1px solid $separator-color !important;
  background: $input-background !important;
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.react-select__option {
  background: $foreground-color !important;
  color: $primary-color !important;

  &:hover,
  &:active {
    background: $theme-color-1 !important;
    color: $button-text-color !important;
  }
}

.react-select__option--is-selected {
  background: $theme-color-1 !important;
  color: $button-text-color !important;
}

.react-select__control--is-focused {
  border-color: rgba($theme-color-1, 0.6) !important;
}

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid $separator-color !important;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: $theme-color-1 !important;
}

.react-select .react-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba($theme-color-1, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: $foreground-color !important;
  border-width: 1px !important;
}

.react-select__single-value {
  bottom: 0 !important;
  top: 50% !important;
  padding-top: 2px !important;
}

.react-datepicker {
  background-color: $input-background !important;
  border: $separator-color !important;
}

.react-datepicker__input-container input {
  background-color: $input-background !important;
  border: $separator-color !important;
}

.react-datepicker__input-container input:focus {
  border-color: rgba($theme-color-1, 0.6) !important;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker__input-container input {
  font-size: 0.8rem !important;
  padding: 0.65rem 0.75rem 0.5rem 0.75rem !important;
  height: calc(2.4rem + 3px) !important;
  border: 1px solid $separator-color !important;
  border-radius: $border-radius !important;
  width: 100% !important;
  outline: initial !important;
}

.react-datepicker {
  border: 1px solid $separator-color !important;
  border-radius: $border-radius !important;
  font-family: 'Work Sans', sans-serif;
}

.react-datepicker__header {
  background-color: $input-background !important;
  border-bottom: initial !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px !important;
  height: 35px !important;
  line-height: 2.3rem !important;
  border-radius: 0 !important;
  margin: 0 !important;
  outline: initial !important;
}

.react-datepicker__day:hover {
  background: $separator-color;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: $theme-color-1;
}

.react-datepicker__day--today {
  font-weight: 400 !important;
  background: $separator-color;
  color: $button-text-color !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: $foreground-color !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: $separator-color !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: $primary-color !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $primary-color !important;
}

.react-datepicker__input-container input {
  color: $primary-color !important;
}

.react-datepicker__time-container {
  border-left: 1px solid $separator-color !important;
  width: 88px !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: $input-background !important;
  color: $primary-color !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: $button-text-color !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: $foreground-color !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: $separator-color !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: $background-color !important;
}

.react-datepicker__triangle {
  left: 30px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: $theme-color-1 !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: $separator-color !important;
}

.react-datepicker.embedded {
  border: initial !important;
  width: 100% !important;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 14.28% !important;
  }

  .react-datepicker__month-container {
    width: 100% !important;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 97px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 30px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: $theme-color-1 !important;
}

.dropzone {
  min-height: 115px !important;
  border: 1px solid $separator-color !important;
  background: $input-background !important;
  padding: 10px 10px !important;
  border-radius: $border-radius !important;
  color: $primary-color !important;
  height: auto !important;

  .img-thumbnail {
    height: 58px !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid $separator-color !important;
  border-radius: $border-radius !important;
  background: $foreground-color !important;
  color: $primary-color !important;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: $theme-color-1;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: $theme-color-1 !important;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: $primary-color !important;
  }

  .dz-error-mark {
    color: $button-text-color !important;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" +encodecolor($theme-color-1 )+";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" +encodecolor($foreground-color )+";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:" +encodecolor($foreground-color )+";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-success-mark {
    color: $button-text-color;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" +encodecolor($theme-color-1 )+";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" +encodecolor($foreground-color )+";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
      background: $theme-color-1 !important;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color !important;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color !important;
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
    color: $theme-color-1 !important;
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}

.custom-switch {
  display: block !important;
  background: $separator-color !important;
  width: 58px !important;
  height: 27px !important;
  border: 1px solid $separator-color !important;

  &:after {
    width: 18px !important;
    height: 18px !important;
    top: 3px !important;
    margin-left: 3px !important;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: $foreground-color !important;
  }

  &.rc-switch:after {
    box-shadow: none !important;
  }

  &.rc-switch-checked:after {
    left: 30px !important;
    box-shadow: none !important;
  }

  &.rc-switch-checked.custom-switch-primary {
    background: $theme-color-1 !important;
    border: 1px solid $theme-color-1 !important;
  }

  &.rc-switch-checked.custom-switch-secondary {
    background: $theme-color-2 !important;
    border: 1px solid $theme-color-2 !important;
  }

  &.custom-switch-primary-inverse {
    border: 1px solid $separator-color !important;
  }

  &.rc-switch-checked.custom-switch-primary-inverse {
    background: $input-background !important;
    border: 1px solid $theme-color-1 !important;

    &:after {
      background: $theme-color-1 !important;
    }
  }

  &.custom-switch-secondary-inverse {
    border: 1px solid $separator-color !important;
  }

  &.rc-switch-checked.custom-switch-secondary-inverse {
    background: $input-background !important;
    border: 1px solid $theme-color-2 !important;

    &:after {
      background: $theme-color-2 !important;
    }
  }
}

.custom-switch .custom-switch-input+.custom-switch-btn {
  background: $separator-color !important;
}

.custom-switch.custom-switch-primary .custom-switch-input:checked+.custom-switch-btn {
  background: $theme-color-1 !important;
  border: 1px solid $theme-color-1 !important;
}

.custom-switch.custom-switch-secondary .custom-switch-input:checked+.custom-switch-btn {
  background: $theme-color-2 !important;
  border: 1px solid $theme-color-2 !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input+.custom-switch-btn {
  border: 1px solid $separator-color !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn {
  background: $input-background !important;
  border: 1px solid $theme-color-1 !important;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
  background: $theme-color-1 !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input+.custom-switch-btn {
  border: 1px solid $separator-color !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn {
  background: $input-background !important;
  border: 1px solid $theme-color-2 !important;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
  background: $theme-color-2 !important;
}

.custom-switch .custom-switch-input+.custom-switch-btn:after {
  background: $input-background !important;
}

.custom-switch .custom-switch-input+.custom-switch-btn {
  border-color: $separator-color !important;
}

.input-group-text {
  border-radius: $border-radius;
  background-color: lighten($input-background, 5%);
  border-color: $separator-color;
  color: $primary-color;
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.form-control {
  border-radius: $border-radius;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.7rem 0.75rem 0.65rem 0.75rem;
  line-height: 1.5;
  border: 1px solid $separator-color;
  background: $input-background;
  color: $primary-color;
}

@keyframes autofill {
  to {
    color: $primary-color;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $primary-color !important;
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  border-radius: $border-radius;
}

.custom-select {
  border-radius: $border-radius;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-select {
  height: calc(2.5rem + 2px);
}

.custom-select:focus {
  border-color: $theme-color-1;
}

.custom-file-input:focus~.custom-file-label {
  border-color: rgba($theme-color-1, 0.6);
}

.custom-file-label::after {
  background: $foreground-color;
  color: $primary-color;
  border-color: $separator-color;
}

.custom-file-input {
  box-shadow: initial !important;
}

.custom-file-label {
  background: $foreground-color;
  border-color: $separator-color;
}

.custom-file-label {
  box-shadow: initial !important;
  border-radius: $border-radius;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-file {
  height: calc(2.5rem + 2px);
}

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: $theme-color-1;
}

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.jumbotron {
  background: initial;
}

/* 22.Slider(Range) */
.rc-slider-tooltip {
  background: $input-background !important;
  color: $primary-color !important;
  border: 1px solid $separator-color !important;
  border-radius: $border-radius !important;
  text-align: center !important;
  top: 150% !important;
  bottom: unset !important;
  padding: 5px !important;
  transform: translateX(-50%) !important;
}

.rc-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px !important;
  background: $foreground-color !important;
  cursor: default !important;
  border: 1px solid $separator-color !important;
  box-shadow: initial !important;
}

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
  height: 7px !important;
}

.rc-slider-rail {
  border: 1px solid $separator-color !important;
  background: $foreground-color !important;
}

.rc-slider-handle {
  margin-top: -7px !important;
}

.rc-slider-track {
  background: $theme-color-1 !important;
}

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: $foreground-color;
  background-color: $theme-color-1;
}

.nav-pills .nav-link {
  border-radius: 50px;
}

.nav-link {
  padding: 0.5rem 1rem;

  @include respond-below(sm) {
    padding: 0.5rem 0.5rem;
  }

  &.active {
    color: $theme-color-1;
  }
}

.page-item {
  padding: 10px;

  @include respond-below(sm) {
    padding: 3px;
  }

  .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0;

    @include respond-below(xs) {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }

    &.next {
      background: $theme-color-1;
      color: $foreground-color;
      border: 1px solid $theme-color-1;
    }

    &.prev,
    &.prev {
      background: $theme-color-1;
      border: 1px solid $theme-color-1;
      color: $foreground-color;
    }

    &.first,
    &.last {
      background: transparent;
      color: $theme-color-1;
      border: 1px solid $theme-color-1;
      border-radius: 30px;

      &:hover {
        background: $theme-color-1;
        color: $foreground-color;
        border: 1px solid $theme-color-1;
      }
    }

    &:hover {
      background-color: transparent;
      border-color: lighten($theme-color-1, 10%);
      color: $theme-color-1;
    }
  }

  &.active .page-link {
    background: transparent;
    border: 1px solid $theme-color-1;
    color: $theme-color-1;
  }

  &.disabled .page-link {
    border-color: $separator-color;
    color: $separator-color;
    background: transparent;
  }
}

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: $primary-color;
}

.btn-sm.page-link {
  padding: 0.5rem 0.5rem;
}

.pagination-lg {
  .page-item {
    padding: 15px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 50px;
      height: 50px;

      @include respond-below(sm) {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem;
      }

      @include respond-below(xs) {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
    }
  }
}

.pagination-sm {
  .page-item {
    padding: 6px;

    @include respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }
  }
}

/* 24.Tooltip and popover */
.popover {
  border-radius: $border-radius;
  background-color: $foreground-color;
  border-color: $separator-color;

  .popover-body {
    color: $primary-color;
  }
}

.popover-header {
  background-color: transparent;
  border-bottom: initial;
}

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  min-width: 250px;
  color: $primary-color;
  background-color: $foreground-color;
  border-radius: 0.1rem;
  border: 1px solid $separator-color;

  .btn.btn-primary {
    margin-right: 5px;
  }
}

.tooltip.show {
  opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: $separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: $foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: $separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: $foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: $foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: $separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: $foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: $separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: $foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: $separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: $foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: $separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: $foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: $separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: $foreground-color;
}

.search-sm {
  position: relative;

  input {
    background: none;
    outline: initial !important;
    border: 1px solid $secondary-color;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    color: $primary-color;
  }

  &:after {
    font-family: "simple-line-icons";
    content: "\e090";
    font-size: 14px;
    border-radius: 10px;
    color: $secondary-color;
    position: absolute;
    width: 25px;
    right: 2px;
    text-align: center;
    cursor: pointer;
    top: 2px;
  }
}

.notification {
  border-radius: $border-radius;
  @include depth(1);
  padding: 25px 25px 25px 25px;
}

.notification:before {
  content: "";
  display: none;
}

.notification-primary {
  color: $theme-color-1;
  background-color: $foreground-color;
  border: 1px solid $theme-color-1;
  opacity: 1;

  .notification-message {
    .title {
      color: $theme-color-1;
    }

    .message {
      color: $primary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $theme-color-1;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-secondary {
  color: $theme-color-2;
  background-color: $foreground-color;
  border: 1px solid $theme-color-2;
  opacity: 1;

  .notification-message {
    .title {
      color: $theme-color-2;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $theme-color-2;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-info {
  color: $info-color;
  background-color: $foreground-color;
  border: 1px solid $info-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $info-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $info-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-success {
  color: $success-color;
  background-color: $foreground-color;
  border: 1px solid $success-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $success-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $success-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-warning {
  color: $warning-color;
  background-color: $foreground-color;
  border: 1px solid $warning-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $warning-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $warning-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-error {
  color: $error-color;
  background-color: $foreground-color;
  border: 1px solid $error-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $error-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $error-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

/* 25.Progress */
.progress {
  background-color: $separator-color-light;
  height: 3px;
}

.progress-bar {
  background-color: $theme-color-1;
}

.CircularProgressbar-text {
  fill: $primary-color !important;
}

.progress-bar-circle {
  width: 54px;
  height: 54px;

  svg path:first-of-type {
    stroke: $separator-color;
  }

  svg path:last-of-type {
    stroke: $theme-color-1;
  }

  &.progress-bar-banner {
    svg path:first-of-type {
      stroke: darken($theme-color-1, 15%);
    }

    svg path:last-of-type {
      stroke: $button-text-color;
    }
  }
}

.progress-banner {
  height: 200px;
  background-image: linear-gradient(to right top,
      $gradient-color-2,
      $gradient-color-3,
      $gradient-color-1 );
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer;

  .CircularProgressbar .CircularProgressbar-text {
    fill: $button-text-color !important;
  }

  .lead {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

    @include respond-below(lg) {
      font-size: 1.2rem;
      margin-bottom: 0.2rem;
    }
  }

  i {
    font-size: 2.7rem;
    margin-bottom: 1rem;

    @include respond-below(lg) {
      font-size: 2rem;
      margin-bottom: 0.2rem;
    }
  }

  .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px;

    svg path:last-of-type {
      stroke: darken($button-text-color, 10%);
    }

    @include respond-below(lg) {
      width: 80px;
      height: 80px;
    }
  }

  .progress-bar-banner {
    .progressbar-text {
      color: $primary-color !important;
      font-size: 1.7rem;
      width: 110px;
      font-weight: 300;

      @include respond-below(lg) {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }

  &:hover {
    background-position: right top;
  }
}

/* 26.Rating */
.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: $theme-color-1 !important;
}

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: $theme-color-1 !important;
}

.react-rater {
  display: block !important;
  font-size: 16px !important;
  color: $separator-color !important;
}

.react-rater-star {
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1 !important;
  font-family: "simple-line-icons";
  color: $separator-color !important;
  margin-right: 3px !important;
  font-size: 18px !important;
  float: left !important;

  &.is-active,
  &.will-be-active {
    color: $theme-color-1 !important;
  }

  &:after {
    content: "\e09b";
  }
}

/* 27.Sortable */
.sortable {
  cursor: default;
}

.sortable span {
  vertical-align: middle;
}

.sortable-ghost {
  opacity: 0.5;
}

/* 28.Spinner */
.spinner {
  width: 36px;
  text-align: center;
}

.spinner>span {
  width: 6px;
  height: 6px;
  background-color: $button-text-color;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* 29.Croppper */
.cropper-line {
  background-color: $theme-color-1;
}

.cropper-point {
  background-color: $theme-color-1;
}

.cropper-view-box {
  outline-color: $theme-color-1;
  outline: $theme-color-1;
}

.cropper-preview {
  overflow: hidden;
}

#cropperContainer {
  height: 300px;
  display: none;
}

/* 30.Modal */
.modal {

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1.75rem;
  }

  .modal-header {
    border-bottom: 1px solid $separator-color;
  }

  .modal-footer {
    border-top: 1px solid $separator-color;
  }

  .close {
    color: $primary-color;
    text-shadow: initial;
  }
}

.modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: $foreground-color;
}

.modal-dialog {
  @include depth(2);
}

.modal-right {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 480px;
  }

  .modal-content {
    min-height: 100%;
  }

  .modal-header {
    height: $navbar-height;

    @include respond-below(xl) {
      height: $navbar-height-lg;
    }

    @include respond-below(lg) {
      height: $navbar-height-md;
    }

    @include respond-below(sm) {
      height: $navbar-height-xs;
    }
  }

  .modal-footer {
    justify-content: left;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

/* 31.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  @include depth(1);

  .image-side {
    width: 40%;
    background: url("/assets/img/login-balloon.jpg") no-repeat center top;
    background-size: cover;
    padding: 80px 40px;

    .h3 {
      line-height: 0.8rem;
    }
  }

  .form-side {
    width: 100%;
    padding: 80px;
    background: #fff;
    a:not(.btn) {
      color: $theme-color-1;
    }
  }

  @include respond-below(md) {
    flex-direction: column;

    .image-side {
      width: 100%;
      padding: 60px;
    }

    .form-side {
      width: 100%;
      padding: 60px;
    }
  }

  @include respond-below(sm) {
    p.h2 {
      font-size: 1.6rem;
    }
  }

  @include respond-below(xs) {
    flex-direction: column;

    .image-side {
      padding: 35px 30px;
    }

    .form-side {
      padding: 35px 30px;
    }

    .logo-single {
      margin-bottom: 20px;
    }

    p.h2 {
      font-size: 1.4rem;
    }
  }
}

/* 32.Html Editors */
.html-editor {
  height: 350px !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $theme-color-1;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: $theme-color-1;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: $theme-color-1;
}

.ql-toolbar.ql-snow {
  border-color: $separator-color;
}

.ql-container.ql-snow {
  border-color: $separator-color;
}

.html-editor-bubble {
  height: 200px !important;
}

.html-editor-bubble .ql-editor {
  border: 1px solid $separator-color;
}

.ql-tooltip {
  z-index: 4;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  height: 350px !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
  box-shadow: initial !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
  border-color: $separator-color;
}

// .ck.ck-toolbar {
//   background: !important;
// }

.RichEditor-root {
  background: $foreground-color;
  border: 1px solid $separator-color;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid $separator-color;
  cursor: text;
  font-size: 14px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 200px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid $separator-color;
  color: $secondary-color;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: $secondary-color;
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Old Standard TT', serif;
  font-size: 12px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: $separator-color;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  color: $primary-color;
  font-size: 0.76rem;
}

.RichEditor-activeButton {
  color: $primary-color;
}

.ql-editor {
  height: 200px !important;
}

.ql-container.ql-bubble {
  border: 1px solid $separator-color;
}

/* 33.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center;
  float: left;
  height: 100px;

  .glyph-icon,
  .fa {
    font-size: 32px;
  }

  .author-name {
    display: none;
  }

  .class-name {
    font-size: 0.76rem;
    color: $muted-color !important;
  }

  @include respond-below(lg) {
    width: 16.66%;
  }

  @include respond-below(md) {
    width: 20%;
  }

  @include respond-below(sm) {
    width: 25%;
  }

  @include respond-below(xs) {
    width: 50%;
  }
}

.chart-container {
  height: 500px;
}

.theme-colors {
  width: 280px;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: $foreground-color;
  @include depth(1);
  transform: translate(280px, -50%);
  transition: transform 0.4s ease-out;
  padding-top: 10px;
  padding-bottom: 10px;

  .theme-button {
    position: absolute;
    left: -34px;
    background: $foreground-color;
    padding: 13px 7px 13px 7px;
    border-radius: 0.2rem;
    color: $primary-color;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $theme-color-1;
  }

  .theme-color {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 20px;
    transition: background 0.25s;

    &.active,
    &:hover {
      background: $foreground-color;
    }

    span {
      display: none;
    }
  }

  .theme-color-purple {
    border: 3px solid $theme-color-purple;
    background: $theme-color-purple;
  }

  .theme-color-blue {
    border: 3px solid $theme-color-blue;
    background: $theme-color-blue;
  }

  .theme-color-orange {
    border: 3px solid $theme-color-orange;
    background: $theme-color-orange;
  }

  .theme-color-green {
    border: 3px solid $theme-color-green;
    background: $theme-color-green;
  }

  .theme-color-red {
    border: 3px solid $theme-color-red;
    background: $theme-color-red;
  }

  &.shown {
    transform: translate(0, -50%);
  }
}

/* 34.Floating Label */
.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label::after,
.has-float-label>span::after {
  background: $foreground-color !important;
}

.has-top-label label,
.has-top-label>span,
.has-float-label label,
.has-float-label>span {
  color: rgba($primary-color, 0.7);
}

.has-top-label {
  .react-select__value-container {
    height: calc(2.4rem + 3px);
  }
}

.has-float-label label,
.has-float-label>span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label label::after,
.has-float-label>span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-float-label .form-control::placeholder {
  opacity: 1;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important;
}

.has-top-label {
  display: block;
  position: relative;

  .react-tagsinput-input {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1;
  }
}

.has-top-label .react-select__value-container .css-rsyb7x {
  margin: 0 !important;
  padding: 0 !important;
}

.has-top-label label,
.has-top-label>span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600;
}

.has-top-label label::after,
.has-top-label>span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::-moz-placeholder {
  opacity: 1;
}

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1;
}

.has-top-label .form-control::placeholder {
  opacity: 1;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.has-top-label .form-control:placeholder-shown:not(:focus)+* {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em;
}

.has-top-label .react-select__single-value {
  top: unset !important;
  bottom: -3px !important;
  margin-left: 0 !important;
}

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-group .has-top-label .form-control {
  width: 100%;
}

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}

/* 35.Loading */
.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($theme-color-1, 0.2);
  border-radius: 50%;
  border-top-color: $theme-color-1;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* 36.Context Menu */
.react-contextmenu {
  box-shadow: initial;
  border-radius: $border-radius;
  background: $input-background;
  border: 1px solid rgba($primary-color, 0.15);
  padding: 0.5rem 0;
  opacity: 0;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: $input-background;
  color: $primary-color;
  cursor: pointer;

  span {
    font-family: 'Work Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px;
  }

  &:hover {
    color: $primary-color;
    text-decoration: none;
    background-color: $background-color;
  }
}

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: $primary-color;
  text-decoration: none;
  background-color: $background-color;
}

.card.react-contextmenu--visible,
.card.active {
  @include depth(2);
}

/* 37.Videojs */
.video-js .vjs-big-play-button {
  background: $input-background;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: initial;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid $input-background;

  .vjs-icon-placeholder {
    color: $theme-color-1;
  }
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: darken($input-background, 5%);
  border-color: darken($input-background, 5%);
}

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important;
}

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem);

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    margin-right: 0.5em;
    background: $input-background;
    color: $theme-color-1;
    border-radius: 15px;
    @include depth(2);
  }

  .vjs-progress-control.vjs-control {
    background: $input-background;
    border-radius: 15px;
    margin-right: 0.5em;
    @include depth(2);
  }

  .vjs-mute-control.vjs-control {
    box-shadow: initial;
  }

  .vjs-progress-holder {
    height: 3px;
    font-size: 1.6em !important;
  }

  .vjs-load-progress,
  .vjs-load-progress div {
    background-color: rgba($theme-color-1, 0.2);
  }

  .vjs-play-progress:before {
    font-size: 0.55em;
    top: -0.2em;
  }

  .vjs-progress-holder {
    margin: 0 17px;
  }

  .vjs-slider {
    text-shadow: initial !important;
    outline: initial !important;
    background-color: darken($separator-color-light, 10%);
  }

  .vjs-play-progress {
    background: $theme-color-1;
  }

  .vjs-play-progress:before {
    color: $theme-color-1;
  }

  .vjs-volume-horizontal {
    margin-left: -1.5em;
    width: 4em;
  }

  .vjs-volume-panel .vjs-volume-level {
    background: $theme-color-1;
  }
}

.video-js.audio {
  background: initial;

  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    display: flex;
  }

  .vjs-fullscreen-control {
    display: none;
  }

  .vjs-control-bar {
    margin-bottom: 0;
  }

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-progress-control.vjs-control {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-mute-control {
    box-shadow: initial !important;
  }

  .vjs-loading-spinner {
    display: none !important;
  }
}

/* 38.Tables */
.table th,
.table td {
  border-color: $separator-color-light !important;
}

.table .thead-light th {
  background-color: $separator-color-light !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $separator-color-light !important;
}

.feature-row {
  margin-top: 80px;

  @include respond-below(sm) {
    margin-top: 40px;
  }
}

/* 39.Invoice*/

.invoice-react {
  .invoice-contents {
    background-color: $foreground-color;
    /* height: 1200px; */
    /* max-width: 830px;*/
    position: relative;
  }
}

@media print {
  body {
    background: $foreground-color;
    height: 100%;
  }

  main {
    margin: 0 !important;
  }

  .navbar,
  .sidebar,
  .theme-colors {
    display: none;
  }

  main .container-fluid .row:not(.invoice) {
    display: none;
  }

  .invoice {
    width: 100%;
    max-width: 100%;

    &.row {
      margin: 0;
    }

    [class*="col"] {
      padding: 0;
    }

    .invoice-contents {
      width: 100%;
      max-width: 100% !important;
      height: 1370px !important;
    }
  }
}

/* 40. Additional Styles */

.bg-black {
  background: #f2f2f2;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.bg-green {
  background: #078742;
  margin-left: 10px;
  margin-right: 10px;
}

.mt-sm {
  margin-top: 20px;
}

.register-checkbox {

  .custom-checkbox,
  .custom-checkbox-label {
    display: inline-block;
  }

  .custom-control-inline {
    margin-right: 0px;
  }
}

.red {
  color: #dc3545;
}

.todays-inspections {
  .list-item-heading {
    font-weight: 700;
  }

  .scrollbar-container {
    max-height: 93%;
    height: auto;
  }
}

.new-inspector {
  i {
    font-size: 28px;
    line-height: 42px;
  }

  .new-steps {
    display: inline-block;
    margin-right: 10px;
  }
}

.dashboard-map {
  .map-item {
    height: 500px;
  }
}

.in-progress.dashboard-list-with-thumbs {
  height: 360px;
}

.dashboard_calender .rbc-row-segment {
  .rbc-event-content {
    text-overflow: initial;
    white-space: normal;
  }

  .rbc-event {
    border-radius: 4px;
    padding: 2px 5px;
  }
}

.page-tabs {
  .nav-tabs li.nav-item {
    font-size: 1.2rem;
    flex: 1;
    text-align: center;
  }
}

.action-btns .btn-sm {
  padding: 0.5rem 0.7rem;
}

.image-view-title.card-subtitle {
  margin-bottom: 0.5rem;
}

.image-view-card-body.card-body {
  padding: 1.25rem 1.25rem 0.8rem 1.25rem;
}

.register-checkbox {
  .invalid-feedback {
    left: 5px;
    right: 5px;
  }
}

.color-green {
  color: #28a745;
}

.theme-text-color {
  color: $theme-color-1;
}

.theme-text-secondary-color {
  color: $theme-color-2;
}

.custom-icon-size {
  font-size: 1.2rem;
}

.btn-outline-primary[aria-expanded="true"] {
  background: $theme-color-1;
  color: #fff;
}

.im-inline-block {
  display: inline-block !important;
}

.pull-left {
  float: left !important;
}

.custom-color-picker {
  z-index: 999;
  position: absolute;
}

.custom-color-picker-btn {
  border-radius: 0;
}

.round-tip {
  width: 20px;
  height: 20px;
  line-height: 10px;
  padding: 0.25rem;
}

.color-box {
  width: 30px;
  height: 30px;
}

.remove-section {
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.sort-ratings {
  font-size: 30px;
}



.with-switch {
  .custom-switch-primary {
    vertical-align: top;
  }

  .custom-switch-primary+.h6 {
    display: inline-block;
    width: calc(100% - 84px);
  }
}

.iconsminds-yelp:before {
  margin: 0;
}

.inline-sortable {
  li {
    float: left;
    padding: 2px 12px;
    border: 1px solid $theme-color-1;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    text-transform: uppercase;

    p {
      margin-bottom: 0;
    }
  }

  .active-navgation {
    li {
      background: $theme-color-1;
      color: #fff;
    }
  }
}

.btn-group>.rouned-list-btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.round-btn-options {
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.metric-card {
  i {
    font-size: 2.5rem;
    color: $theme-color-1;
  }

  p.card-text {
    font-size: 1rem;
    color: #8f8f8f;
    padding: 10px;
  }

  p.lead {
    color: $theme-color-1;
    margin-bottom: 0;
  }
}


.dropdown-edit .rt-td {
  &:last-child {
    overflow: visible;
  }
}

.no-box-shadow {
  box-shadow: none !important;
}

.help-target {
  .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
  }

  .breadcrumb-item.active {
    color: $theme-color-1;
    font-weight: 600;
  }
}

.help-support-container {
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;

  .card {
    width: 100%;
  }

  .left-content {
    color: $theme-color-1;
    font-size: 2rem;
  }

  .right-content {
    h5 {
      color: $theme-color-1;
    }
  }
}

.settings-menus {
  .card {
    background: transparent;
    box-shadow: none;

    .btn-outline-primary {
      border: 0;
    }
  }
}

.section-list {
  .section-li {
    font-size: 1rem;
    display: block;
    padding: 5px 12px;
    border-radius: 7px;

    .section-options {
      display: none;

      &.float-right i {
        font-size: 0.7rem;
      }
    }

    .section-name {
      font-size: 1rem;
    }

    &:hover,
    &.active {
      background: $theme-color-1;
      color: #fff;

      i {
        color: #fff;
      }

      .section-options {
        display: inline;
      }

      a {
        background: $theme-color-1;
        color: #fff;
      }
    }

    a:hover,
    a.active {
      background: $theme-color-1;
      color: #fff;
    }
  }
}

.attachment-container {
  font-size: 1rem;

  .attachment-li {
    i {
      color: $theme-color-1;
    }

    a.btn-link {
      font-size: 1rem;
    }

    .text-muted {
      font-size: 0.8rem;
      padding: 0 6px;
    }

    &:hover {
      background: $theme-color-1;
      color: #fff;

      i,
      a.btn-link {
        color: #fff;
      }

      .text-muted {
        color: #e2e2e2 !important;
      }
    }
  }
}

.swal-footer {
  direction: rtl;
  text-align: center;
}

.section-item-container {
  .section-item {
    .section-item-options {
      display: none;

      &.float-right i {
        font-size: 0.7rem;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .item-name {
      font-size: 1rem;
    }

    &:hover,
    &.active {
      background: $theme-color-1;
      color: #fff;

      .btn-outline-primary {
        color: #fff;
      }

      i {
        color: #fff;
      }

      .section-item-options {
        display: inline;
        margin-top: -30px;

        i {
          padding: 0 5px;
        }
      }
    }

    .section-item-btn {
      width: calc(100% - 100px);
    }
  }

  .section-item-inner-contianer {
    background: #fff;
    color: $theme-color-1;

    .h4 {
      font-size: 1rem;
    }

    hr {
      margin-top: 0.5rem;
      border-color: $theme-color-1;
    }

    .add-comment-btn {
      margin-top: 7px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    ul {
      padding-top: 12px;
    }

    .comment-li {
      font-size: 1rem;
      display: block;
      padding: 5px 12px;

      i {
        color: $theme-color-1;
      }

      .comment-options {
        display: none;

        &.float-right i {
          font-size: 0.7rem;
          padding-right: 8px;
        }
      }

      .comment-name {
        font-size: 0.9rem;
      }

      &:hover,
      &.active {
        background: $theme-color-1;
        color: #fff;

        i {
          color: #fff;
        }

        .comment-options {
          display: inline;
        }
      }
    }

  }

}

.edit-quesiton {
  .state-show-btn {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .edit-button,
  .view-mode {
    display: none;
  }

  .edit-mode {
    display: block;
  }

  .view-button {
    display: inline-block;
  }
}

.view-quesiton {

  .view-button,
  .edit-mode {
    display: none;
  }

  .edit-button {
    display: inline-block;
  }

  .view-mode {
    display: block;
  }
}

.report-head {
  position: absolute;
  padding: 10px 15px;
  background: #ffffff9e;
  width: 100%;
  bottom: 0;

  .insepector-detail {
    background: $theme-color-1;
    color: #fff;
    padding: 0px;
    border-radius: 500px;

    .person-head {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }

    .insepector-content {
      vertical-align: middle;
    }
  }
}

.report-detail-options {
  background: $theme-color-1;
  padding: 10px 20px;
  height: 100%;

  .btn-secondary {
    color: $theme-color-1;
    background: #fff;
    border-color: #fff;

    &:hover {
      background: $theme-color-1;
      color: #fff;
    }
  }
}

.round-select-option {
  background: $theme-color-1;
  border-radius: 50%;
  padding: 8px 10px;
  color: #fff;
  margin-right: 10px;
}

.react-select__single-value {
  .round-select-option {
    display: none;
  }
}

.display-icon-btn {
  background: $theme-color-1;
  padding: 5px 20px;
  color: #fff;
}

.img-responsive {
  max-width: 100%;
}

.orange-bg {
  background: $theme-color-orange;
}

.red-bg {
  background: #dc3545;
}

.theme-bg {
  background: $theme-color-1;
}

.black-color {
  color: #000 !important;
}

.white-color {
  color: #fff !important;
}

.summary-count {
  font-size: 3rem;
  width: 8rem;
  height: 8rem;
  line-height: 8rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.w-50px {
  width: 50px;
  height: 50px;
}

.w-40px {
  width: 40px;
  height: 40px;
}

.cursor-pointer {
  cursor: pointer;
}



.staff-scroll {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;

  .invalid-feedback {
    top: 208px;
    left: auto;
    position: fixed;
    z-index: 999;
  }
}

.timezone-slots {
  &:hover {
    background: $theme-color-1;

    p {
      color: #fff !important;
    }
  }
}

.btn-round {
  padding: 0.75rem 0.9rem 0.6rem 0.95rem;
}

.center-content-shelf {
  align-items: center;
  flex-direction: row;
  width: 166px;
}

.shelf-width {
  width: 100px;
  min-height: 175px;
}


.center-content-grow {
  align-items: center;
  flex-direction: row;
  width: 166px;
}

.growroom-width {
  width: 150px;
  min-height: 225px;
}

.growtable-width {
  width: 120px;
  min-height: 120px;
}

.growplant-width {
  width: 120px;
  min-height: 120px;
}

.plant-count {
  z-index: 200;
  position: absolute;
  top: 3px;
  right: 4px;
  background: #f84a30;
  color: #fff;
  padding: 4px 5px;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: 900;
  min-width: 24px;
}

.center-position-number {
  height: 100%;

  .Droppable {
    height: inherit;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.bg-theme-color,
.tooltip-inner {
  background-color: #00aee8;
  color: #fff;
  border-radius: 7px;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #00aee8 !important;
  color: #00aee8;
}

.special-full-row {
  width: 100%;
}

.rounded-corners {
  border-radius: 7px;
}

.job-folders {
  height: 100%;
  border-radius: 7px;

  span {
    width: calc(100% - 1.85rem);
    margin-top: -3px;
  }
}

.room-active {
  background: $theme-color-1;
  color: #fff;
}

.action-btns {
  .rouned-list-btn {
    margin-top: 0 !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important
  }

  .btn-group {
    margin-bottom: 0 !important;
  }

  .drop-link {
    padding: 0.5rem 1.5rem
  }
}

.swal-overlay {
  z-index: 910000;
}

.small-padd-table .ReactTable .rt-th,
.small-padd-table .ReactTable .rt-td {
  padding-top: 15px;
}

.page-break-style {
  page-break-before: always;
}

.shelf-package-width {
  width: 150px;
  min-height: 270px;
  padding: 7px 0;

  h1 {
    opacity: 0.5;
  }
}

.shelf-product-package {
  display: block;
  width: 100%;
  background: $theme-color-1;
  color: #fff;
  padding: 7px;
  font-size: 0.7rem;
}

.currency-img {
  img {
    width: 100%;
  }
}

.checked-in-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.pos-top-left {
  width: 80px;
}

.pos-top-right {
  width: calc(100% - 80px);
}

.pos-product-wrapper {
  background: #f2f5f5;
  min-height: 690px;

  .product-item-search {
    background: #fff;
  }

  .product-item {
    cursor: pointer;
    padding: 8px 5px;
    text-align: center;
    margin-bottom: 0px;
    background: #fff;
    border: 1px solid #ccc;

    .product-item-name {
      font-size: 0.78rem;
      font-weight: 700;
      line-height: 1;
    }
  }
}

.pos-show-product-wrapper {
  min-height: 280px;
}

.compact-cart-form {
  .form-control {
    padding: 4px 7px;
    border-radius: 2px;
  }

  .form-group {
    margin-bottom: 0;
  }

  select.form-control:not([size]):not([multiple]) {
    height: calc(1.7rem + 3px);
  }

  .cart-op-icons {
    i {
      font-size: 1.2rem;
    }
  }

  input.form-control::-webkit-outer-spin-button,
  input.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.print_pos_label {
  .row_data {
    width: 100%;
    clear: both;
  }

  .panel-body {
    padding: 0;
    margin: 0;
  }

  .panel-body:after,
  .panel-body:before {
    display: table;
    content: " ";
  }
}

.para-mb-0 {
  p {
    margin-bottom: 0px;
  }
}

.form-group-mb-0 {
  .form-group {
    margin-bottom: 0px;
  }
}

.extra-padd-table-responsive {
  padding: 15px !important;

  .react-select {
    position: absolute;
    min-width: 120px;
  }

  .invalid-feedback {
    position: relative;
    margin-top: 0rem;
  }
}

.big-btn-font {
  .btn {
    font-size: 0.9rem;
  }
}

.btn-special-warning {
  background-color: #e65a08;
  border-color: #e65a08;
  color: #fff;
}

.btn-special-info {
  background-color: #0096ef;
  border-color: #0096ef;
  color: #fff;
}

.denomination-qty {
  .input-group>.form-group {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }

  .input-group-text {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.3rem 0.65rem 0.4rem 0.65rem;
    background: #e4e4e4;
    cursor: pointer;
  }
}

.active-facility {
  background: $theme-color-1;
  color: #fff !important;
}

.view-order-table {
  thead th {
    background: #ececec;
  }
}

.processor-container-fill {
  .meter {
    height: 360px;
    /* Can be anything */
    position: relative;
    background: #555;
    -moz-border-radius: 0px 0px 10px 10px;
    -webkit-border-radius: 0px 0px 10px 10px;
    border-radius: 0px 0px 10px 10px;
    padding: 4px;
    -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  }

  .meter>span {
    display: block;
    height: 100%;
    bottom: 4px;
    left: 4px;
    right: 4px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
    background-color: rgb(43, 194, 83);
    background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0, rgb(43, 194, 83)),
        color-stop(1, rgb(84, 240, 84)));
    background-image: -moz-linear-gradient(center bottom,
        rgb(43, 194, 83) 37%,
        rgb(84, 240, 84) 69%);
    -webkit-box-shadow:
      inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    -moz-box-shadow:
      inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    box-shadow:
      inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: absolute;
    overflow: hidden;
  }

  .meter>span:after,
  .animate>span>span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: -moz-linear-gradient(-45deg,
        rgba(255, 255, 255, .2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, .2) 50%,
        rgba(255, 255, 255, .2) 75%,
        transparent 75%,
        transparent);
    background-image: -webkit-gradient(linear, 0 0, 100% 100%,
        color-stop(.25, rgba(255, 255, 255, .2)),
        color-stop(.25, transparent), color-stop(.5, transparent),
        color-stop(.5, rgba(255, 255, 255, .2)),
        color-stop(.75, rgba(255, 255, 255, .2)),
        color-stop(.75, transparent), to(transparent));
    z-index: 1;
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    -webkit-animation: move_fill 2s linear infinite;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
  }

  .animate>span:after {
    display: none;
  }
}

.whitebg-equipment-status {
  background-color: #fff !important;
  font-weight: 700;
}


@-webkit-keyframes move_fill {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

.process-btns {

  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #ffffff;
  }

  .btn {
    white-space: break-spaces;
  }
}

.equipment_details {
  margin-bottom: 20px;

  h3 {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .btn {
    margin-bottom: 15px;
  }

  height:480px;
}

.equipments {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;

  .equipments_status {
    max-width: 300px;
    flex: 0 0 300px;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    white-space: normal;
    /*border-left: 2px solid #cbcbcb;*/
    margin: 0 !important;
  }
}

.align-items-center-table {
  [role="row"] {
    align-items: center;
  }

  .rt-th,
  .rt-td {
    padding-top: 10px;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.kitchen-product-wrapper {
  background: #f2f5f5;
  min-height: 490px;
}

.custom-control-inline-100 {
  .custom-control-inline {
    width: 46%;
  }
}

.m-signature-pad {
  width: 300px;
  margin-left: auto;
  margin-right: auto;

  .m-signature-pad--body {
    position: relative;
    width: 100%;
    border: 2px solid #e2e2e2;
    border-radius: 7px;

    canvas {
      width: 100% !important;
    }
  }
}

.left-aligned-sign {
  .m-signature-pad {
    margin-left: 0;
  }
}

.opacity-list-type-reduce {
  opacity: 0.7;
}

.border-radius-7border-radius-7 {
  border-radius: 7px;
}

.special-table-layout {
  thead {
    th {
      background: #000000;
      color: #ffffff;
    }
  }

  table {
    thead {
      th {
        background: #5d5d5d;
        color: #ffffff;
      }
    }

    td {
      background: #d2d2d2;
    }
  }
}

.text-danger {
  .invalid-feedback {
    display: block;
  }
}

.pagination {
  flex-wrap: wrap;
}

.task-members-wrapper {
  .media-left {
    position: relative;
  }

  .media {
    .offline-dot-user {
      background: #f40d0d;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid #FFF;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }

    .online-dot-user {
      background: #22dc00;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid #FFF;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }
}

.rc-slider-tooltip {
  z-index: 100;
}

.dashboard_calender.staff-scheduler .rbc-row-segment .rbc-event-content {
  white-space: pre;
  text-align: left;
}

.font-normal-size {
  font-size: 84%;
}

.dataTables_wrapper {
  .dt-buttons {
    margin-bottom: 10px;

    button,
    .dt-button:hover:not(.disabled) {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.5rem 1rem;
      font-size: 0.76rem;
      line-height: 1.5;
      border-radius: .25rem;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-radius: 50px;
      outline: medium none invert !important;
      outline: initial !important;
      box-shadow: none !important;
      box-shadow: initial !important;
      background: #00aee8;
      border-color: #00aee8;
      color: #fff;
    }
  }

  .dataTables_filter {
    width: 50%;
    margin-bottom: 10px;

    label {
      width: 100%;

      input {
        width: calc(100% - 60px);
        font-size: 0.8rem !important;
        padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
        height: calc(2.2rem + 3px) !important;
        border: 1px solid #d7d7d7 !important;
        border-radius: 0.1rem !important;
        outline: medium none invert !important;
        outline: initial !important;
        background-color: white !important;
      }
    }
  }

  &.no-footer {
    .dataTables_paginate {
      .paginate_button {
        color: #818181 !important;
        box-shadow: none;
        padding: 5px 10px;
        margin: 0 1px;
        border: 1px solid #ddd;
        border-radius: 3px;
        -webkit-border-radius: 3px;

        &.current,
        &.current:hover {
          background: #00aee8;
          border-color: #00aee8;
          color: #fff !important;
        }

        &:hover {
          background: #00aee8;
          border-color: #00aee8;
        }
      }

    }

  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fafafa !important;
  }

  .w-33 {
    width: 33% !important;
    max-width: 33%;
  }
}

table.dataTable.no-footer.display {
  border-bottom: 0;
}

.table-responsive {
  min-width: 100%;
}

.report-chart-container {
  height: 320px;
}

.report-bar-chart-container {
  height: 520px;
}

.profile-wrapper {
  .profile-img-wrapper {
    display: flex;
    justify-content: center;
    position: relative;

    .profile-img {
      border: 12px solid #e4e4e4;
      display: flex;
      border-radius: 50%;
      overflow: hidden;
      height: 160px;
      width: 160px;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    .edit-profile-img {
      position: absolute;
      bottom: 2px;
      right: 5px;
      font-size: 1.8rem;
      display: inline-block;
      cursor: pointer;
    }
  }

  .upload-progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #5cb85c;
    webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    webkit-transition: width .6s ease;
    o-transition: width .6s ease;
    transition: width .6s ease;
  }

  .upload-progress-bar-wrapper {
    height: 10px;
    margin-top: 10px;
    width: 300px;
    float: left;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 50px;
    webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  }

  .upload-progress-cancel {
    margin-top: 5px;
    webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    float: left;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
  }

  &.page-tabs {
    .nav-tabs {
      li.nav-item {
        text-align: left;
        min-width: 100%;
        margin-bottom: 0.5rem;
      }

      .nav-link.active,
      .nav-link:hover,
      .nav-item.show .nav-link {
        background: $theme-color-1;
        color: #fff;

        &:before {
          display: none;
        }
      }

      .nav-link {
        border-radius: 0.5rem;
        padding-top: 0.5rem;

        &:hover {
          background: #41a915;
        }
      }
    }
  }

  .list-time-special-bg {
    background: #f3f3f3;
    border-radius: 9px;
  }
}

.visitor-settings-wrapper {
  .qrcode-img {
    img {
      max-width: 100%;
    }
  }

  &.page-tabs {
    .nav-tabs {
      li.nav-item {
        text-align: left;
        min-width: 100%;
        margin-bottom: 0.5rem;
      }

      .nav-link.active,
      .nav-link:hover,
      .nav-item.show .nav-link {
        background: $theme-color-1;
        color: #fff;

        &:before {
          display: none;
        }
      }

      .nav-link {
        border-radius: 0.5rem;
        padding-top: 0.5rem;

        &:hover {
          background: #41a915;
        }
      }
    }
  }

  .company-img-wrapper {
    display: flex;
    justify-content: center;
    position: relative;

    .company-img {
      border: 3px solid #e4e4e4;
      display: flex;
      height: 170px;
      border-radius: 7px;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-width: 100%;

      }
    }
  }
}

.tridant-form-builder {
  .react-form-builder {
    .react-form-builder-toolbar {
      padding-right: 0px;
      padding-left: 10px;

      ul li {
        padding: 10px 4px;
        margin-left: 0;
        margin-right: 0;

        i {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

    }

    .react-form-builder-preview {
      border: 0px;
      box-shadow: none;
      min-height: 450px;

      .Sortable .rfb-item {
        padding: 10px 20px 5px;
      }

      .edit-form .dynamic-option-list {
        ul li input.form-control {
          padding: 0.4rem 0.65rem 0.4rem 0.65rem;
        }

        ul li button {
          padding: 0.4rem 0.6rem 0.3rem 0.6rem;
          border-radius: 10px;
        }

        &.staff-option-list {
          display: none;
        }
      }

      .rdw-editor-wrapper {
        .rdw-editor-toolbar {
          display: none;
        }

        .rdw-editor-main {
          height: 64px;
          width: 100%;
          overflow: hidden;
          border: 1px solid #bfbfbf;
        }
      }

      .react-datepicker__close-icon {
        display: none;
      }

      .toolbar-header-buttons {
        div:nth-child(3) {
          display: none;
        }

        .btn {
          padding: 0.4rem 0.6rem 0.3rem 0.6rem;
        }
      }

      .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
        right: 0px;
      }

      .Sortable .rfb-item.SortableItem .form-group {
        margin-bottom: 5px;
      }
    }
  }
}

.visitor-name-style {
  color: $theme-color-1;
  margin-bottom: 1.5rem;
}

.visitor-feedback-form {
  #feedback_message {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}

.react-rater-star.font-awesome-star {
  margin-right: 11px !important;
  font-size: 24px !important;
  color: $theme-color-1 !important;
  font-family: 'Font Awesome 5 Free';

  &:after {
    content: "\f005";
  }

  &.will-be-active,
  &.is-active {
    font-weight: 900;
  }
}

.small-size-rating {
  .react-rater-star.font-awesome-star {
    margin-right: 5px !important;
    font-size: 16px !important;
  }
}

.visitor-print-badge {
  width: 520px;
  background: #ececec;
  border-radius: 7px;
  padding: 1rem 0.5rem;

  .visitor-print-badge-img {
    width: 80px;
    height: 80px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.subscriber-action {
  position: absolute;
  top: 0;
  right: 0;
}

.subscriber-user-wrapper {
  img {
    max-height: 120px;
    width: auto;
    max-width: 100%;
  }

  .subscriber-user-detail {
    line-height: 1.4rem;
  }
}

.logged-in-as-user-bar {
  background: $theme-color-1;
  padding: 7px;
  z-index: 1050;
  color: #fff;
  text-align: center;
}

.logged-in-as-user-bar-exist {
  top: 18px !important;
}

.badge-lg {
  font-size: 1rem;
}

.alpha-sort .btn {
  font-size: 1rem;
}

.white-tooltip {
  .tooltip-inner {
    background: #fff;
    color: #000;
  }
}

.small-text-tooltip {
  font-size: 0.68rem;
}

.edit-email-template-view {
  width: 100%;
  overflow-x: scroll;
}


.ic-r {
  border: 1px solid #000;
  border-radius: 50px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-form-wrapper {
  .form-control {
    border-radius: 10px !important;
    border: 1px solid #000 !important;
  }

  fieldset {
    .form-control {
      background: transparent;
      border-radius: 0px !important;
      border: 0 !important;
    }
  }
}

.btn-work-about {
  background-color: #000;
  border: 1.5px solid transparent;
  border-radius: 1.25rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.25;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  padding-top: 0.60rem;
  padding-bottom: 0.60rem;
}

.f-w {
  font-weight: 400;
}

.f-w-7 {
  font-weight: 700;
}

.white {
  color: #fff;
}

.p-l-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.m-l-30 {
  margin-left: 10px;
  margin-right: 10px;
}

.h-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.h-80 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.br-btn {
  border: 1px solid #000;
  border-radius: 20px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.25rem;
  font-weight: 700;
  padding-bottom: 0.25rem;
  width: 17.5rem;
}

.br-dr {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  font-size: 14px;
  opacity: .8;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 17.5rem;
}

.discount-text {
  font-size: 36px;
  font-weight: 700;
  color: #000;
}

.grey {
  background: #efeeee;
  border-radius: 25px;
}

.black {
  color: #000;
}

.main-pages-layout h1 {
  color: #fff !important;
}

.read-more {
  border-bottom: 1.5px solid #000;
  letter-spacing: 0.125rem;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}

.f-20 {
  font-size: 1.5rem;
  color: #000;
}

.f-16 {
  font-size: 16px;
  color: #ffffff;
  line-height: 1.5rem;
}

.single-image {
  overflow: hidden;

  a {
    img {
      width: 100%;
      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
  }

  &:hover a img {
    transform: scale(1.1);
  }
}

.single-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 890px;
}

.no-products-text {
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1.8rem;
  font-weight: 600;
}

.ql-editor {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
  }
}

.dynamic-page-content {
  color: #ffffff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
  }

  h2 {
    font-weight: 700;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-right {
    text-align: right;
  }

  p {
    font-size: 16px;
    color: #fff;
    line-height: 1.5rem;
    font-weight: 400;
  }
}

.front-page-banner {
  height: 400px;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    width: 100%;
    color: #fff !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
  }

  h1 {
    font-size: 3.1rem;
  }

  h3 {
    font-size: 1.95rem;
  }

  .front-page-header-wrapper {
    height: 100%;

    .front-page-header-text {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.front-home-slider {
  /*height:100vh !important;
	max-height:600px !important;*/
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    width: 100%;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 700;
  }

  h1 {
    font-size: 3.1rem;
  }

  h3 {
    font-size: 1.95rem;
  }

  .dynamic-page-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 50px 40px;
    width: 100%;
    color: #fff;
  }

  .dynamic-page-content p {
    margin-bottom: 0px;
    color: #fff !important;
    ;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

.offcanvas-navigation {
  ul {
    list-style: none;
    padding-left: 0px;

    li a {
      font-size: 0.91rem;
    }
  }
}

.mobile-off-canvas {
  .mobile-aside-button {
    padding-top: 8px;
    padding-left: 8px;
  }
}

.contact-map-wrapper {
  iframe {
    width: 100% !important;
  }
}

.hide-shop-list-wrap .shop-list-wrap {
  display: none;
}

.mailchimp-subscription-form {
  input {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 20px;
    font-size: 14px;
    opacity: .8;
    padding-left: 3.25rem;
    padding-right: 3.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    max-width: 220px;
  }

  button {
    border: 1px solid #000;
    border-radius: 20px;
    padding-left: 1.55rem;
    padding-right: 1.55rem;
    padding-top: 0.45rem;
    font-weight: 700;
    padding-bottom: 0.25rem;
    margin-left: 8px;
  }
}

.btn-black {
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.front-nav-bar-fac {
  padding: 0 !important;
  height: auto;
  box-shadow: none;
  background: transparent;

  .header-icon {
    font-size: 20px !important;
    margin-right: -15px;
    margin-left: 20px;
    margin-top: -5px;
  }
}

.dataTables_wrapper {
  overflow-x: scroll;
}

#app-container .sidebar .sub-menu ul[data-parent="myreports"] li {
  margin-left: 15px;
}

.logo-mobile-img {
  width: 36px;
  height: 36px;
}

.swiper-container {
  .product-wrap {
    .product-img {
      text-align: center;
    }

    .product-img img {
      width: auto;
      max-height: 330px;
      max-width: 100%;
    }
  }
}

.testimonial-wrapper {
  position: relative;

  .default-img {
    max-height: 140px;
  }

  .author-img {
    max-height: 80px;
    border-radius: 50%;
  }

  .react-rater-star.font-awesome-star {
    color: #f8b805 !important;
  }

  .small-size-rating .react-rater-star.font-awesome-star {
    font-size: 20px !important;
  }

  .h6 {
    font-size: 1.15rem;
  }

  .review-type-img img {
    width: 80px;
  }

  @media (min-width: 536px) {
    .swiper-review-next {
      right: -30px;
    }

    .swiper-review-prev {
      left: -30px;
    }
  }

  @media (max-width: 535px) {
    .swiper-slide {
      padding: 0px 30px;
    }
  }
}

.navbar-logo-img .img-responsive {
  max-height: 100%;
}

.menu-home .main-page-menu li:last-child {
  margin-right: 0px;

  a {
    padding-right: 0px;
  }
}

.scrollbar-container {
  max-height: 540px;
}

.pro-pagination-style ul li.page-item {
  padding: 10px 3px;

  .page-link {
    line-height: 1.2;
    width: auto;
  }

  &.active {
    .page-link {
      color: #fff;
    }
  }
}

.shop-topbar {
  .select-shoing-wrap {
    align-items: center;
  }

  input {
    border-radius: 9px;
  }

  .shop-select {
    margin-top: 10px;
    height: 45px;

    select {
      height: inherit;
      border-radius: 9px;
    }
  }
}

.product-by-category-list {
  .sidebar-widget:last-child {
    display: none;
  }

  .shop-select select {
    padding: 10px !important;
    margin-top: 5px;
  }

  .shop-top-bar .select-shoing-wrap p {
    margin-top: 14px;
  }
}

.shop-by-category-item {
  padding: 10px 20px;
  display: block;
  background: #000;
  color: #fff;
  border-radius: 29px;
  margin-right: 10px;
  font-size: 1.2rem;
  min-width: 140px;
  text-align: center;
}

.shop-by-category-item-img {
  width: 250px;
  height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.default-category-img {
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  border-radius: 18px;
  width: 100%;
  height: 100%;
}

.hZfaLM {
  border-bottom: none;
  margin-bottom: 0.5rem;
  overflow: inherit;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  .lazy-img-observer {
    inset: 0px;
    position: absolute;
  }
}

span.applicable-product-name {
  background: $theme-color-1;
  padding: 5px 12px;
  border-radius: 500px;
  white-space: nowrap;
  color: #fff;
  margin-bottom: 10px;
  display: inline-block;
}

.color-span-width-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
}

.deal-count-number {
  background: #f00;
  color: #fff;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}

.printer-settings-left-addon {
  .input-group-text {
    min-width: 70px;
  }
}

#app-container .sidebar .scrollbar-container {
  max-height: none;
}

.mix-and-match-scrollbar {
  min-height: 330px;
}

.inventory-list-thumb {
  width: 60px;
  margin-right: 10px;
  display: inline-block;
}

.inventory-list-thumb+span {
  display: inline-block;
}

.inventory-list-switch {
  &.custom-switch {
    width: 44px !important;
    height: 20px !important;
  }

  &.custom-switch:after {
    width: 14px !important;
    height: 14px !important;
    top: 2px !important;

  }

  &.rc-switch-checked:after {
    left: 22px !important;
  }
}

.blink-deal-effect {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}


//landing page styles

.home-header {

  li {
    font-size: 16px !important;
  }
}

.landing-root {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px !important;
  color: #fff;

  h1,
  h3,
  h6,
  h4,
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
  }

  h3 {
    margin-bottom: 25px;
  }

  p {
    font-size: 16px;
  }

  .q-section-main {
    min-height: 600px;
    background: rgb(247, 247, 247) !important;

    h1 {
      font-size: 75px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 900;

      @media only screen and (max-width: 991px) {
        font-size: 50px;
      }
    }

    .q-header-img {
      height: 100%;
      width: auto;
      background: url(/assets/img/landing-page/vibe/head2.jpg);
      background-position: bottom center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      position: relative;

      @media only screen and (max-width: 768px) {
        display: none !important;
      }
    }

    .tribe-header-img {
      background: url(/assets/img/landing-page/tribe/sect2.jpg);

      @media only screen and (max-width: 768px) {
        display: none !important;
      }
    }

    .qual-header-img {
      background: url(/assets/img/landing-page/quality/qualityHeader2.jpg);

      @media only screen and (max-width: 768px) {
        display: none !important;
      }
    }
  }

  .q-section {
    min-height: 300px;
    padding: 60px 0px !important;
    color: #ffffff;

    .data-sect {
      a {
        outline: initial !important;
        box-shadow: none !important;
        font-size: .8rem;
        padding: .75rem 1.3rem .6rem;
        background-color: #000 !important;
        border: .09375rem solid #000 !important;
        color: #fff !important;
        border-radius: 1.25rem !important;
      }
    }
  }

  .base-section {
    .data-sect {
      min-height: 350px !important;
    }
  }

  .testim-solo {

    min-height: 300px;
    background: rgb(247, 247, 247) !important;
    display: flex;
    align-items: center;

    .quote-test {
      &:before {
        font-family: 'Montserrat', sans-serif !important;
        content: '“';
        position: absolute;
        left: 6%;
        top: -60%;
        font-size: 200px;
        color: rgb(218, 218, 218) !important;
      }

      &:after {
        font-family: 'Montserrat', sans-serif !important;
        font-weight: 900;
        content: '”';
        position: absolute;
        right: 6%;
        top: -60%;
        font-size: 200px;
        color: rgb(218, 218, 218) !important;
      }

      @media only screen and (max-width: 991px) {
        &:before {
          display: none
        }

        &:after {
          display: none
        }
      }
    }
  }

  .testim-solos {
    min-height: 300px;
    background: rgb(247, 247, 247) !important;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .sectionImg-container {
    width: 100%;
    height: 350px;
    background-position: center center !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    position: relative;

    @media only screen and (max-width: 991px) {
      float: unset !important;
      margin: 0 auto 35px auto;
    }
  }

  .aboutImg-container {
    width: 100%;
    height: 400px;
    background-position: left 30% !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    position: relative;

    @media only screen and (max-width: 991px) {
      float: unset !important;
      margin: 0 auto 35px auto;
    }
  }

  .pic-descrep {
    font-size: 15px;
  }

  .whatiskava,
  .whatiscbd {
    p {
      color: #4a4a4a !important;
    }

    .kava-three-types {
      width: 100%;
      border-radius: 0;
      background-size: 100% !important;
      background-position: left 0% !important;
    }

    .kava-man {
      width: 100%;
      height: 460px;
      background-size: 105% !important;
      background-position: left -10% !important;

      @media only screen and (max-width: 991px) {
        height: 270px;
      }
    }

    .sectionImg-container {
      height: 400px;
      background-position: left 30% !important;
    }
  }

  .quality {
    .sectionImg-container {
      height: 300px;
      width: 300px;
      border-radius: 100%;
      background-position: center center !important;
      background-size: 70% !important;
      background-repeat: no-repeat !important;

      &:before {
        height: 320px;
        width: 320px;
        border-radius: 100%;
      }
    }
  }

  .whatiscbd {

    .cbd-header-img {
      background: url(/assets/img/landing-page/whatiscbd/headerimg.jpg);
      height: 100%;
      width: 100%;
      background-position: right 40% !important;
    }

    .color-cbd {
      background: #f2dfc9 !important;
    }

    .cbdsect2 {
      height: 460px;
      background-position: 45% bottom !important;
      background-size: 110% !important;

      @media only screen and (max-width: 991px) {
        height: 270px;
      }
    }
  }
}

.sidebar-widget-list-left {
  .sub_categories {
    display: none;
    margin-top: 10px;
    margin-left: 30px;
  }

  button.active+.sub_categories {
    display: block;
  }
}



@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@media (max-width: 1024px) {
  .subscriber-user-wrapper {
    .subscriber-user-detail {
      width: 100%;

      &.ml-3 {
        margin-left: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right-wrap {
    justify-content: flex-start !important;
  }
}

@media (max-width: 992px) {
  .main-pages-layout .main-page-menu li {
    margin-right: 5px;

    a {
      padding: 1rem 0.5rem;
      font-size: 13px !important;
    }
  }

  .front-page-banner {
    h1 {
      font-size: 2.8rem;
    }

    h3 {
      font-size: 1.75rem;
    }
  }

  .main-header-wrapper {
    .container {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;

      .col-7 {
        padding-left: 0px;
      }
    }
  }

  .main-footer-wrapper {
    .container {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media (min-width: 992px) {
  .metric-cards-container {
    .col-lg-3 {
      -webkit-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .app-menu {
    width: 340px;
  }

  .navbar .navbar-logo-img {
    width: 190px;
    height: 45px;
  }

}

@media (min-width: 769px) {
  .automation-btns {
    .col-lg-3 {
      max-width: 23%;
    }
  }

  .modal-right .modal-dialog {
    max-width: 60%;
  }

  .modal-right.modal-right-lg .modal-dialog {
    max-width: 87%;
  }

  .not-swiper-slide.col-lg-4 {
    min-width: 33.33333%;
  }
}

@media (min-width: 700px) and (max-width: 812px) {
  .modal-dialog {
    max-width: 700px;
  }
}

@media (min-width: 768px) and (max-width: 812px) {
  .tridant-form-builder .col-md-9.react-form-builder-preview {
    flex: 0 0 64%;
    max-width: 64%;
  }

  .tridant-form-builder .col-md-3.react-form-builder-toolbar {
    flex: 0 0 36%;
    max-width: 36%;

    ul li {
      font-size: 0.8rem;
      margin-left: 0;
      margin-right: 0;

      i {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 768px) {
  .im-inline-block {
    &.custom-switch {
      margin-bottom: 12px;
    }
  }

  .custom-switch.im-inline-block+p {
    margin-left: 0px !important;
    padding: 0;
  }

  .btn-lg,
  .btn-group-lg>.btn {
    padding: 0.75rem 1.4rem 0.6rem 1.4rem;
  }

  .staff-scroll {
    height: auto;
    overflow: auto;
  }

  .tridant-form-builder .react-form-builder .react-form-builder-toolbar {
    position: relative;
    bottom: auto;
    border-top: 0px;
    margin-top: 15px;

    ul {
      height: auto;
    }

    h4 {
      background: $theme-color-1;
    }
  }

  .subscriber-user-wrapper {
    .subscriber-user-detail {
      width: auto;

      &.ml-3 {
        margin-left: 1rem !important;
      }
    }
  }

  .navbar .navbar-logo.navbar-logo-img {
    width: 170px;
    height: auto;
  }
}

@media only screen and (min-width: 800px) and (max-width: 805px) {
  .mob-800 {
    display: none !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 805px) {
  .swiper-slide {
    text-align: center !important;
  }
}

@media (max-width: 480px) {
  .navbar #iconMenuDropdown {
    width: 300px;
    height: 460px;

    .icon-menu-item {
      text-align: left;
      /*i{
				display:none !important;
			}*/
    }
  }

  .custom-file-label {
    font-size: 10px;
  }

  .custom-file-label:after {
    font-size: 12px;
  }

  .with-switch {
    .btn-primary.btn-xs {
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }

  .mobile-font-less {
    label {
      font-size: 0.6rem;
    }
  }

  .mobile-show .d-sm-inline {
    display: inline !important;
  }

  .mobile-margin-top {
    margin-top: 20px;
  }

  .mobile-left {
    float: left;
    width: auto;
    padding: 0 10px;
  }

  .mobile-right {
    float: right;
  }

  .tab-content {
    .float-sm-right {

      .btn-sm,
      .btn-group-sm>.btn {
        padding: 0.5rem 0.5rem;
        font-size: 0.66rem;
      }
    }

    .check-all {
      padding: 0.5rem 0.9rem !important;

      .custom-control {
        padding-left: 0.6rem;
      }
    }
  }

  .mobile-template-options {
    .btn-primary.btn-sm {
      font-size: 0.6rem !important;
    }
  }

  .mobile-btn-nowrap {
    .btn {
      white-space: normal;
    }
  }

  label .custom-switch.im-inline-block+span.h6 {
    font-size: 0.8rem;
  }

  .section-list .section-li .section-name {
    font-size: 0.85rem;
  }

  .section-item-container .section-item-inner-contianer .h4 {
    font-size: 0.85rem;
  }

  .section-item-container .section-item-inner-contianer .comment-li .comment-name {
    font-size: 0.7rem;
  }

  .report-head {
    position: relative;

    .float-right {
      float: left !important;
    }
  }

  .report-detail-options {
    .float-right {
      float: left !important;
    }
  }

  .nav-tabs .nav-link {
    font-size: 0.9rem;
  }

  .sm-responsive-btn {
    &.btn-group {
      display: block;

      button.btn:not(.dropdown-toggle) {
        border-top-left-radius: 50px !important;
        border-top-right-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        margin-right: 8px;
        padding: 0.75rem 1rem 0.6rem 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modal-right .modal-dialog {
    min-width: 100%;
  }

  .tridant-form-builder .react-form-builder .react-form-builder-toolbar {
    padding-left: 0px;
  }

  .discount-text {
    font-size: 20px;
  }

  .mailchimp-subscription-form button {
    margin-left: 0px;
    margin-top: 10px;
    padding-top: 0.25rem;
  }

  .footer-layout .main-footer-links {
    padding-left: 0px;
    margin-bottom: 30px;

    li {
      margin-right: 0px;

      a {
        padding: 0.5rem 0.5rem;
        font-size: 0.72rem;
      }

      &:first-child {
        a {
          padding-left: 0px;
        }
      }
    }
  }

  .logo-single {
    width: 220px;
    background-size: contain;
  }

  .auth-card .form-side {
    padding: 40px !important;
  }

  .mobile-slide-padding {
    .swiper-slide {
      padding: 0 50px;
    }
  }

  .navbar .navbar-logo.navbar-logo-img {
    width: 36px;
  }
}


.swiper-wrapper .dynamic-page-content {
  p {
    font-size: 62px;
    line-height: 1;
    color: #ffffff !important;
    font-family: 'Montserrat', sans-serif !important;

    @media (max-width: 768px) {
      font-size: 40px !important;
    }
  }

}

.info-card {
  margin-top: -20px;
  position: absolute;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;

  @media (max-width: 1020px) {
    display: none;
  }
}

.divider {
  &::after {
    content: " ";
    border-right: 1px solid #ececec;
    padding-top: 10px;
    padding-bottom: 10px;

  }
}

.product-wrap .product-img .product-action .pro-cart,
.product-list-image-wrap .product-img .product-action .pro-cart {
  width: 100% !important;
  border-radius: 14px !important;
  background: none;

  button {
    border-radius: 0px 0px 14px 14px;
    background: rgba(#f00, 0.7) !important;
    transition: 0.3s;
  }
}

.product-name-container {
  min-height: 40px !important;
}

#updateSettingsForm {
  .btn {
    background-color: transparent !important;
    color: $theme-color-1;
  }
}

.swiper-container {
  .text-left {
    @media (max-width: 768px) {
      text-align: center !important;
    }
  }
}

#image-renderer {
  background: #fff !important;
}

.rc-switch-disabled {
  background: #e7e7e7 !important;
  border-color: #e7e7e7 !important;
}

.list-item-heading {
  .btn-link {
    text-decoration: none;
  }
}